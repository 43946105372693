import React from "react";
import { Link, useNavigate } from "react-router-dom";
import VideoPopUp from "../../components/Helpers/VideoPopup";
import useToggle from "../../Hooks/useToggle";
import dateFormat from "../../lib/dateFormat";
import img from "../../assets/images/azlogomessage.jpeg";
import img1 from "../../assets/images/az1.jpeg";
import img2 from "../../assets/images/az2.jpeg";
import img3 from "../../assets/images/az3.jpeg";
import img4 from "../../assets/images/az4.jpeg";
import img5 from "../../assets/images/adazindia.jpeg";
import { formatDateToMMDDYYYY } from "../../lib/constant";
import { motion } from "framer-motion";

export const imgArray = [img, img1, img2, img3, img4, img5];

export default function PostCardStyleOne(props) {
  const {
    children,
    datas = {
      image: "",
      category: "",
      date: "",
      title: "",
      index: "0",
      id: "",
      data: {},
      isAnnouncment: false,
    },
    className = "",
    video = true,
  } = props;
  const [show, setValue] = useToggle(false);
  const navigate = useNavigate();

  return (
    <div className="announcement-box">
      <div
        className={`binduz-er-trending-news-list-box ${className || ""} `}
        onClick={() => {
          if (!datas?.isAnnouncment) {
            navigate(`/ticket-details/${datas?.data?.id}`, {
              state: datas?.data,
            });
          } else {
            navigate(`/details/${datas?.id}`, {
              state: { ...datas?.data, isMegaphone: true },
            });
          }
        }}
      >
        <div
          className="binduz-er-thumb"
          style={{ marginBottom: !video ? "0" : "", height: "260px" ,display:'flex'}}
        >
          <img
            src={datas?.image || imgArray[datas?.index] || imgArray[0]}
            // src={require(`../../assets/images/azlogomessage.jpeg`).default}
            alt=""
          />
          {video && (
            <div className="binduz-er-play">
              <a
                onClick={(e) => setValue.toggle(e)}
                className="binduz-er-video-popup"
              >
                <i className="fas fa-play"></i>
              </a>
            </div>
          )}
        </div>
        <div
          className="binduz-er-content"
          style={{
            // backgroundColor: "#f7f7f7",
            textAlign:'center',
            height: "248px",
          }}
        >
          <div className="binduz-er-meta-item">
            <div className="binduz-er-meta-categories">
              {datas?.isAnnouncment && (
                <motion.a
                  animate={{ opacity: [1, 0.1, 1] }}
                  transition={{
                    duration: 1.5, // time for one blink
                    repeat: Infinity, // blink indefinitely
                    ease: "easeInOut", // smooth easing
                  }}
                >
                  {datas?.category}{" "}
                </motion.a>
              )}
            </div>
            <div className="binduz-er-meta-date">
              <span>
                <i className="fal fa-calendar-alt" style={{marginRight:'5px'}}></i>
                {"  "}
                {formatDateToMMDDYYYY(new Date(datas?.date))}
              </span>
            </div>
          </div>
          <div className="binduz-er-trending-news-list-title">
            <h7 className="binduz-er-title line-clump-5">
              <div
                // to={`/ticket-details/${datas?.id}`}
                onClick={() => {
                  //  navigate(`/ticket-details/${datas?.id}`, {
                  //    state: datas?.data,
                  //  });
                }}
              >
                <Link
                  dangerouslySetInnerHTML={{
                    __html: datas?.title,
                  }}
                />
              </div>
            </h7>
            {children && children}
          </div>
        </div>
      </div>
    </div>
  );
}
