import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Overlay, Tooltip } from "react-bootstrap";
import { formatDateToMMDDYYYY, getDatefromBackend } from "../../lib/constant";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import PremiumModal from "../../containers/PremiumModal";
import { toast } from "react-toastify";
import { paymentRenewEventApi } from "../../services/AuthServices";

const EventDetails = ({ setselectedTab, data, amountData }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [premiumAmount, setpremiumAmount] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [id, setid] = useState("");
  const [show, setShow] = useState(null); // Track which button is hovered
  const [target, setTarget] = useState(null); // Track the target button ref

  const navigate = useNavigate();

  useEffect(() => {
    getAmount();
  }, []);

  const handleMouseEnter = (event, id) => {
    setShow(id); // Set the ID of the hovered button
    setTarget(event.target); // Set the target to the hovered button's DOM element
  };

  const handleMouseLeave = () => {
    setShow(null); // Reset the hover state
  };

  const getAmount = async () => {
    const event = amountData?.find((a) => a?.type === "Event");
    if (event) {
      setpremiumAmount(Number(event?.amount));
    }
  };

  const handleEdit = (id, index) => {
    // Handle edit logic here
    navigate(`/event-update/${id}`, { state: data[index] });
  };

  const renewPayment = async () => {
    setisLoading(true);
    await paymentRenewEventApi({ id: id })
      .then((response) => {
        setisLoading(false);

        response?.data?.data?.url
          ? window.location.replace(response?.data?.data?.url)
          : toast("Something went wrong,Please try again after some time", {
              type: "error",
            });
      })
      .catch((error) => {
        setisLoading(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  return (
    <div
      style={{
        marginTop: "10px",
        background: "none",
        padding: "10px",
        paddingLeft: "50px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "end" }}>
        <h2
          className="classifield-text "
          style={{
            height: "40px",
            display: "flex",
            textAlign: "end",
            marginTop: "20px",
            cursor: "pointer",
            alignItems: "center",
          }}
          onClick={() => navigate("/event-post")}
        >{`POST EVENT `}</h2>
      </div>
      {!_.isEmpty(data) ? (
        <div className="table-responsive">
          <Table striped bordered hover className="table-fixed custom-table">
            <thead className="thead-dark" style={{ backgroundColor: "#" }}>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item?.name}</td>
                  <td>{formatDateToMMDDYYYY(new Date(item?.created_at))}</td>

                  <td>
                    {item?.type === "Premium" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="warning"
                          size="sm"
                          className="mr-2"
                          onClick={() => handleEdit(item?.id, index)}
                        >
                          <i className="fal fa-edit"></i>
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="warning"
                          size="sm"
                          onMouseEnter={(e) => handleMouseEnter(e, item.id)}
                          onMouseLeave={handleMouseLeave}
                          className="mr-2"
                          onClick={() => {
                            setModalIsOpen(true);
                            setid(item?.id);
                          }}
                        >
                          Get Premium
                        </Button>
                        <Overlay
                          target={target}
                          show={show === item.id}
                          placement="top"
                        >
                          {(props) => (
                            <Tooltip id={`tooltip-${item.id}`} {...props}>
                              To edit event, please upgrade to Premium
                            </Tooltip>
                          )}
                        </Overlay>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PremiumModal
            isAccDetails={true}
            title={"EVENT"}
            amount={premiumAmount}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            descArray={[
              " Your event will be highlighted in our bi-monthly newsletter once or twice, depending on the event date.",
              " Your event will be included in 3 targeted posts in our message center, ensuring maximum views and traction.",
              " Enjoy a minimum of 4 to 5 expertly crafted social media posts for Facebook and Instagram, written by our human content writers.",
              " We’ll share your event in 3 to 4 local community WhatsApp groups,spreading the word to your target audience.",
            ]}
            titleArray={[
              "⁠Newsletter Feature:-",
              "⁠Message Center Posts:-",
              "Social Media Promotion:-",
              "⁠Local Community Outreach:-",
            ]}
            topLinesArray={[
              "You can post your event for free on our platform!",
              "If you want to attract more attendees and gain greater visibility for your event, consider upgrading to our Premium option.By making your event Premium,",
              "You’ll receive these exclusive benefits:-",
            ]}
            lastLine={`Upgrade to Premium which costs only ${premiumAmount} and make your event a standout success!`}
            enrollPremiumTitle={isLoading ? "Loading..." : "Enroll For Premium"}
            yesClicked={() => {
              renewPayment();
            }}
          />
        </div>
      ) : (
        <h5 style={{ marginTop: "80px", textAlign: "center" }}>
          No Data Found
        </h5>
      )}
    </div>
  );
};

export default EventDetails;
