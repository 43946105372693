import React, { useEffect, useState } from "react";

import PlanCard from "../../containers/PlanCard";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getSubscriptionData,
  subscriptionPayment,
} from "../../services/AuthServices";
import _ from "lodash";

const MainSection = () => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isDisable, setisDisable] = useState(false);

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  // const classifield = queryParams.get("classifield");
  // const bussiness = queryParams.get("bussiness");

  let fullName = localStorage.getItem("full_name");

  useEffect(() => {
    getData();
  }, []);

  const handlePlanChange = (plan) => {
    if (selectedPlan?.includes(plan)) {
      let array = [];
      for (let a of selectedPlan) {
        if (a !== plan) {
          array?.push(a);
        }
      }
      setSelectedPlan(array);
    } else {
      setSelectedPlan([...selectedPlan, plan]);
    }
  };

  const getData = async () => {
    setisLoading(true);
    await getSubscriptionData()
      .then((response) => {
        setdata(response?.data?.data);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const proccedToPayment = async () => {
    setisDisable(true);

    let formdata = new FormData();

    let amount = selectedPlan?.length * 25;

    formdata.append("amount", amount);
    formdata.append("is_classified", selectedPlan?.includes("Classified"));
    formdata.append("is_business", selectedPlan?.includes("Local Bussiness"));
    formdata.append("is_doctor", selectedPlan?.includes("Doctor"));

    await subscriptionPayment(formdata)
      .then((response) => {
        setisDisable(false);

        response?.data?.data?.url &&
          window?.location?.replace(response?.data?.data?.url);
      })
      .catch((error) => {
        setisDisable(false);

        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      // style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row d-flex">
          <div className="subscription-page col-lg-6">
            <h3
              style={{ marginBottom: "28px" }}
            >{`Welcome to AZindia, ${fullName} !`}</h3>
            {/* <h2>Select your Magical plan</h2> */}
            <div className="plan-container">
              <PlanCard
                plan="Classified"
                isActive={data?.classified?.is_purchased}
                price="$25 /30 Days"
                description="For the new marketer on a budget who just wants basic tracking ..."
                selectedPlan={selectedPlan}
                onPlanChange={handlePlanChange}
              />
              <PlanCard
                plan="Local Business"
                price="$25 /30 Days"
                isActive={data?.business?.is_purchased}
                // isActive={true}
                description="For the new marketer on a budget who just wants basic tracking ..."
                selectedPlan={selectedPlan}
                onPlanChange={handlePlanChange}
              />
              <PlanCard
                plan="Doctor"
                price="$25 /30 Days"
                isActive={data?.doctor?.is_purchased}
                description="For the new marketer on a budget who just wants basic tracking ..."
                selectedPlan={selectedPlan}
                onPlanChange={handlePlanChange}
              />
            </div>
            <button
              className="continue-button"
              style={{ backgroundColor: _.isEmpty(selectedPlan) && "gray" }}
              disabled={_.isEmpty(selectedPlan) || isDisable}
              onClick={proccedToPayment}
            >
              {isDisable ? "Loading..." : "Proceed to payment "}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
