import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const MainSection = () => {
  const location = useLocation();
  const [data, setdata] = useState(location?.state);

  let pages;
  let to;
  if (data?.isAzTimes) {
    pages = "AZ Times";
    to = "/articles";
  } else if (data?.isMegaphone) {
    pages = "Megaphone";
    to = "/megaphone";
  } else {
    pages = "Top Stories";
    to = "/top-stories";
  }

  useEffect(() => {
   window.scrollTo({
     top: 0,
     behavior: "smooth", // Smooth scroll effect
   });
  }, [])
  

  const formatDate = (date) => {
    const optionsDate = {
      weekday: "long",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const formattedDate = date?.toLocaleDateString("en-US", optionsDate);

    return `${formattedDate}`;
  };

  return (
    <>
      {/* <Breadcrumb home="Home" pages={pages} to={to} title={data?.title} /> */}
      <div className="binduz-er-author-user-area">
        <div className=" container">
          <div className="row">
            <motion.div
              className=" col-lg-12"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 1 }}
              transition={{ duration: 4 }}
            >
              <div
                className="binduz-er-author-box"
                style={{
                  background: "none",
                  marginBottom: "0px",
                  paddingBottom: "0px",
                }}
              >
                {data?.image && (
                  <div className="binduz-er-thumb col-lg-3" style={{display:'flex'}}>
                    <img
                      src={data?.image}
                      alt=""
                      className=" image-shadow"
                    />
                    {/* <span>30 Post</span> */}
                  </div>
                )}
                <div
                  className="binduz-er-content col-lg-9"
                  style={{
                    padding: "7px",
                    // textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="binduz-er-meta-author">
                    <h4> {data?.title}</h4>
                  </div>
                </div>
              </div>
              <div className="m-5 mb-5">
                <div className="binduz-er-meta-item ">
                  {/* <div className="binduz-er-meta-categories">
                  <a href="#">Technology</a>
                </div> */}
                  <div className="binduz-er-meta-date">
                    <span>
                      <i className="fal fa-calendar-alt"></i>
                      {"    "}
                      {formatDate(new Date(data?.created_at))}
                    </span>
                  </div>
                </div>
                {/* <h3 className="binduz-er-title">
                <a ></a>
              </h3> */}
                <div className="binduz-er-text mt-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.content,
                    }}
                  />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainSection;
