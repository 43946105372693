// FullScreenCarousel.js
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import "./index.css"; // Import your custom CSS

const ImagePopUp = ({
  images,
  isOpen,
  setIsOpen,
  deleteClicked,
  addClicked,
}) => {
  const openCarousel = () => setIsOpen(true);
  const closeCarousel = () => setIsOpen(false);

  return (
    <>
      {isOpen && (
        <div className="fullscreen-carousel-container">
          <button className="close-carousel-button" onClick={closeCarousel}>
            <i className="fal fa-times"></i>
          </button>

          <Carousel
            showThumbs={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            showIndicators={false} // Disable dots here
          >
            {images.map((image, index) => (
              <div>
                <div key={index} className="binduz-er-thumb">
                  <img
                    src={image}
                    alt={`Slide ${index}`}
                    className="image-shadow"
                    height={200}
                  />
                </div>
                <div
                  className="mt-5 "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <button
                    onClick={addClicked}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    ADD
                  </button>
                  <button
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                    onClick={() => deleteClicked(index)}
                  >
                    {/* <i className="fal fa-times"></i> */}
                    DELETE
                  </button>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default ImagePopUp;
