/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import DropDown from "../Helpers/DropDown";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addDoctor,
  getCityData,
  getStateData,
} from "../../services/AuthServices";
import { getDoctorList, getPremiumAmount } from "../../services/HomeServices";
import PremiumModal from "../../containers/PremiumModal";
import { formateMobileNumber, validateNum } from "../../lib/constant";

const ClassiFieldForm = () => {
  const [catList, setcatList] = useState([]);
  const [stateData, setstateData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [isDisable, setisDisable] = useState(false);
  const [premiumAmount, setpremiumAmount] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isFirstTime, setisFirstTime] = useState(true);
  const [selectedImage, setselectedImage] = useState(null);
  const [selectedLogo, setselectedLogo] = useState(null);
  const fileInputRef = useRef(null);
  const logoInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getCategory();
    getState();
    getAmount();
  }, []);

   useEffect(() => {
     window.scrollTo({
       top: 0,
       behavior: "smooth", // Smooth scroll effect
     });
   }, []);

  const getAmount = async () => {
    await getPremiumAmount()
      .then((response) => {
        const event = response?.data?.data?.find((a) => a?.type === "Doctor");
        if (event) {
          setpremiumAmount(Number(event?.amount));
        }
      })
      .catch((error) => {});
  };
  const getState = async () => {
    await getStateData()
      .then((response) => {
        setstateData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const getCity = async (code) => {
    await getCityData(code)
      .then((response) => {
        setcityData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const handeStateChange = (stateId) => {
    setFieldValue("stateId", stateId);
    let code;
    for (let a of stateData) {
      if (a?.id == Number(stateId)) {
        code = a?.state_code;
      }
    }

    getCity(code);
  };

  const getCategory = async () => {
    await getDoctorList("")
      .then((response) => {
        setcatList(response?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const AdminSchema = object().shape({
    email: string()
      .required("Please enter email.")
      .email("Invalid Email Format. Please try again.")
      .trim("Please enter email."),
    name: string().required("Please enter name.").trim("Please enter name."),

    address: string()
      .required("Please enter Address.")
      .trim("Please enter Address."),
    phone: string()
      .required("Please enter Mobile number.")
      .trim("Please enter Mobile number.")
      .test("phone", "Mobile number must be exactly 10 digits", (value) => {
        return validateNum(value);
      }),
    content: string()
      .required("Please enter content.")
      .trim("Please enter content."),
    cate_id: string()
      .required("Please enter category.")
      .trim("Please enter category."),

    stateId: string()
      .required("Please Select State.")
      .trim("Please Select State."),
    cityId: string()
      .required("Please Select City.")
      .trim("Please Select City."),
    zip: string()
      .required("Please enter zip.")
      .trim("Please enter zip.")
      .matches(/^\d{5}$/, "Must be exactly 5 digits"),
  });

  const submitClicked = async (data) => {
    if (!values?.isFree || !isFirstTime) {
      setisDisable(true);
      await addDoctor(data)
        .then((response) => {
          setisDisable(false);

          if (values?.isFree || premiumAmount === 0) {
            toast("Doctor Enquiry added Successfully", { type: "success" });
            navigate("/doctor");
          } else {
            response?.data?.data?.url
              ? window.location.replace(response?.data?.data?.url)
              : navigate("/doctor");
          }
        })
        .catch((error) => {
          setisDisable(false);

          if (error?.response?.status == 422)
            toast(error?.response?.data?.message, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else
            toast(error?.response?.data?.message || "Something went wrong", {
              type: "error",
            });
        });
    } else {
      setModalIsOpen(true);
      setisFirstTime(false);
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: AdminSchema,

    initialValues: {
      email: "",
      name: "",
      phone: "",
      landmark: "",
      address: "",
      logo: null,
      image: null,
      content: "",
      isFree: true,
      cate_id: "",
      stateId: "",
      cityId: "",
      zip: "",
    },
    onSubmit: async () => {
      let formdata = new FormData();

      formdata.append("name", values.name);
      formdata.append("phone", values.phone);
      formdata.append("cate_id", values.cate_id);
      formdata.append("landmark", values.landmark);
      formdata.append("type", values.isFree ? "Standard" : "Premium");
      formdata.append("content", values.content);
      formdata.append("email", values.email);
      formdata.append("address", values.address);
      formdata.append("postal", values.zip);
      formdata.append("state_id", values.stateId);
      formdata.append("city_id", values.cityId);
      formdata.append("amount", values.isFree ? 0 : premiumAmount);
      formdata.append("logo", values.logo);
      formdata.append("image", values.image);

      submitClicked(formdata);
    },

    onReset: () => {},
  });

  const handleImageChange = (event, name) => {
    const file = event?.target?.files[0];
    setFieldValue(name, file);
    if (name === "logo") {
      setselectedLogo(URL.createObjectURL(file));
    } else {
      setselectedImage(URL.createObjectURL(file));
    }
    // const reader = new FileReader();
    // reader.onloadend = () => {
    //   //  setImagePreview(reader?.result);
    // };
    // reader.readAsDataURL(file);
  };

  const removeImage = (name) => {
    setFieldValue(name, null);
    if (name === "logo") {
      setselectedLogo(null)
       logoInputRef.current.value = "";
    } else {
      setselectedImage(null);
      fileInputRef.current.value = "";
    }
  };

  return (
    <div
      className="binduz-er-contact-us-box"
      style={{
        marginTop: "3px",
        background: "none",
      }}
    >
      <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
        <div
          className="row"
          style={{
            // paddingTop: "15px",
            paddingBottom: "15px",
            // gap: "20px",
          }}
        >
          {/* <p>
            To contact AZIndia team please fill out the form below. A member
            from our team will respond to you within 1-2 business days.
          </p> */}
          {/* <h6> For Technical Support Contact us : 1-844-AZINDIA</h6> */}
          <h6 className="mt-1">
            Reach the Indian/Pakistani Community with Your Doctor Listing.{" "}
            <Link
              style={{ textDecoration: "underline", color: "red" }}
              onClick={() => setModalIsOpen(true)}
            >
              Explore Premium!
            </Link>{" "}
          </h6>
          <div>
            <h7 style={{ fontWeight: "700px" }}> Doctor Type: </h7>
            <input
              className="m-2"
              type="radio"
              id="standard"
              onChange={(e) => setFieldValue("isFree", true)}
              checked={values?.isFree}
            />
            <span>Standard (Free) </span>
            <input
              className="m-2"
              type="radio"
              id="premium"
              checked={!values?.isFree}
              onChange={(e) => setFieldValue("isFree", false)}
            />
            <span>Premium (${premiumAmount}) </span>
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Name *"
                name="name"
                id="name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.name && errors?.name && "red",
                }}
              />
            </div>
            {touched?.name && errors?.name && (
              <span className="text-danger">{errors.name}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Email *"
                name="email"
                id="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.email && errors?.email && "red",
                }}
              />
            </div>
            {touched?.email && errors?.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>

          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Mobile Number *"
                name="phone"
                id="phone"
                value={values.phone}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("phone", formateMobileNumber(e?.target?.value));
                }}
                // onChange={handleChange}
                style={{
                  borderColor: touched?.phone && errors?.phone && "red",
                }}
              />
            </div>
            {touched?.phone && errors?.phone && (
              <span className="text-danger">{errors.phone}</span>
            )}
          </div>
          <div className="col-lg-6">
            <div className="binduz-er-input-box">
              <input
                type="text"
                value={"Request for Doctor listing"}
                disabled
                placeholder="Request for Doctor listing"
              />
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="Data"
                name="cate_id"
                onBlur={handleBlur}
                value={values?.cate_id}
                onChange={handleChange}
                style={{
                  borderColor: touched?.cate_id && errors?.cate_id && "red",
                }}
              >
                <option value="">Select Category</option>
                {catList?.map((x) => (
                  <option value={x?.id}>{x?.name}</option>
                ))}
              </select>
              {touched?.cate_id && errors?.cate_id && (
                <span className="text-danger">{errors.cate_id}</span>
              )}
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="State"
                name="stateId"
                id="stateId"
                value={values.stateId}
                onBlur={handleBlur}
                // onChange={handleChange}
                onChange={(e) => handeStateChange(e?.target?.value)}
                style={{
                  borderColor: touched?.stateId && errors?.stateId && "red",
                }}
              >
                <option value="">Select State</option>
                {stateData?.map((x) => (
                  <option value={x?.id}>{x?.state}</option>
                ))}
              </select>
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.stateId && errors?.stateId && (
              <span className="text-danger">{errors.stateId}</span>
            )}
          </div>
          <div className="col-lg-6">
            <div className="binduz-er-input-box ">
              <select
                placeholder="City"
                name="cityId"
                onBlur={handleBlur}
                style={{
                  borderColor: touched?.cityId && errors?.cityId && "red",
                }}
                value={values?.cityId}
                onChange={handleChange}
              >
                <option value="">Select City</option>
                {cityData?.map((x) => (
                  <option value={x?.id}>{x?.city}</option>
                ))}
              </select>
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.cityId && errors?.cityId && (
              <span className="text-danger">{errors.cityId}</span>
            )}
          </div>
          <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <input
                type="number"
                placeholder="Zip *"
                name="zip"
                id="zip"
                value={values.zip}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.zip && errors?.zip && "red",
                }}
              />
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.zip && errors?.zip && (
              <span className="text-danger">{errors.zip}</span>
            )}
          </div>
          <div
            className=" col-lg-12"
            style={{
              textAlign: "center",
              // , paddingTop: "100px"
            }}
          >
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  height: "98px",
                  resize: "auto",
                  borderColor: touched?.address && errors?.address && "red",
                }}
                name="address"
                id="address"
                onBlur={handleBlur}
                value={values?.address}
                onChange={handleChange}
                maxLength={1200}
                cols="3"
                rows="4"
                placeholder="Address*"
              ></textarea>
              {touched?.address && errors?.address && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.address}
                </div>
              )}
              <div
                style={{
                  textAlign: "start",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
              >
                Chars Remaining: {1200 - values?.address?.length}
              </div>
            </div>
          </div>
          <div
            className=" col-lg-12"
            style={{
              textAlign: "center",
              // , paddingTop: "100px"
            }}
          >
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  height: "98px",
                  resize: "auto",
                  borderColor: touched?.landmark && errors?.landmark && "red",
                }}
                name="landmark"
                id="landmark"
                onBlur={handleBlur}
                value={values?.landmark}
                onChange={handleChange}
                cols="3"
                rows="4"
                placeholder="Landmark"
              ></textarea>
              {touched?.landmark && errors?.landmark && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.landmark}
                </div>
              )}
            </div>
          </div>
          <div
            className=" col-lg-12"
            style={{
              textAlign: "center",
              //  paddingTop: "100px"
            }}
          >
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  height: "140px",
                  resize: "auto",
                  borderColor: touched?.content && errors?.content && "red",
                }}
                name="content"
                id="content"
                value={values.content}
                onBlur={handleBlur}
                onChange={handleChange}
                cols="3"
                maxLength={1000}
                rows="2"
                placeholder="Content *"
              ></textarea>
              {touched?.content && errors?.content && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.content}
                </div>
              )}
              <div
                style={{
                  textAlign: "start",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
              >
                {1000 - values?.content?.length} Chars left
              </div>
            </div>
          </div>
          {!values?.isFree && (
            <div className="col-lg-6 ">
              <div className="binduz-er-input-box">
                <label htmlFor="logo" className="custom-file-label">
                  Choose Logo
                </label>
                <input
                  type="file"
                  placeholder="Logo *"
                  accept="image/*"
                  name="logo"
                  ref={logoInputRef}
                  id="logo"
                  onChange={(e) => handleImageChange(e, "logo")}
                  onBlur={handleBlur}
                  className="form-control custom-file-input"
                />
              </div>
              {selectedLogo && (
                <>
                  <a
                    // href={values?.image}
                    href={selectedLogo}
                    target="_blank"
                    className="m-1"
                  >
                    <i className="fal fa-eye" style={{ cursor: "pointer" }} />
                  </a>
                  <a className="m-1" onClick={() => removeImage("logo")}>
                    <i className="fal fa-trash" style={{ cursor: "pointer" }} />
                  </a>
                </>
              )}
              <span>( Max Width/Height: 1150px * 500px )</span>
            </div>
          )}
          {!values?.isFree && (
            <div className="col-lg-6 ">
              <div className="binduz-er-input-box">
                <label htmlFor="image" className="custom-file-label">
                  Choose Image
                </label>
                <input
                  type="file"
                  placeholder="Logo *"
                  accept="image/*"
                  name="image"
                  id="image"
                  ref={fileInputRef}
                  onChange={(e) => handleImageChange(e, "image")}
                  onBlur={handleBlur}
                  className="form-control custom-file-input"
                />
              </div>
              {selectedImage && (
                <>
                  <a
                    // href={values?.image}
                    href={selectedImage}
                    target="_blank"
                    className="m-1"
                  >
                    <i className="fal fa-eye" style={{ cursor: "pointer" }} />
                  </a>
                  <a className="m-1" onClick={() => removeImage("image")}>
                    <i className="fal fa-trash" style={{ cursor: "pointer" }} />
                  </a>
                </>
              )}
              <span>( Max Width/Height: 1150px * 500px )</span>
            </div>
          )}
        </div>
        <button
          type="submit"
          disabled={isDisable}
          onClick={handleSubmit}
          className="binduz-er-main-btn contact-us-box-button mt-5"
        >
          {isDisable
            ? "Loading..."
            : values?.isFree
            ? "Submit"
            : "Proceed to payment"}{" "}
        </button>
      </form>
      <PremiumModal
        yesClicked={() => {
          setFieldValue("isFree", false);
          setModalIsOpen(false);
        }}
        noClicked={() => {
          setFieldValue("isFree", true);
          setModalIsOpen(false);
        }}
        title={"DOCTOR"}
        amount={premiumAmount}
        modalIsOpen={modalIsOpen}
        isLifetime={true}
        isValidityOff={true}
        setModalIsOpen={setModalIsOpen}
        topLinesArray={[
          "You can list your medical practice on our platform for free and connect with the Indian community seeking specialized care. Your free listing will help patients find the right doctor for their needs, fostering a strong, supportive community.",
          `For just $${premiumAmount}, upgrade to Premium and enjoy these exclusive benefits:`,
        ]}
        descArray={[
          " Your practice will be featured in a spotlight position, enhancing visibility and attracting more patients.",
          " Upload profile image of Doc making your listing more engaging and informative.",
          " Appear at the top of search results, making it easier for patients to find your practice first.",
          " By being more visible, you’ll be able to help more members of the Indian community find the specialized care they need.",
        ]}
        titleArray={[
          "⁠Spotlight Position:-",
          "Enhanced Media Uploads:-",
          "Priority Listing:-",
          "Community Support:-",
        ]}
        lastLine={`Upgrading to Premium ensures your practice stands out, attracts more attention, and helps the Indian community connect with the healthcare they deserve. Post for free and upgrade to Premium to maximize your impact and reach within the community.`}
      />
    </div>
  );
};

export default ClassiFieldForm;
