import React, { useEffect, useState } from "react";
import EventCart from "./PostCards/EventCart";
import { useNavigate } from "react-router-dom";
import { getCatDetail } from "../services/HomeServices";
import { formatDate } from "../lib/constant";
import { toast } from "react-toastify";
import Loader from "./Loader";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const EventModule = ({ data, isSearch }) => {
  const [eventDetails, seteventDetails] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [hasAnimatedRight, setHasAnimatedRight] = useState(false);

  const navigate = useNavigate();

  const controlsRight = useAnimation();
  const { ref: refRight, inView: inViewRight } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewRight && !hasAnimatedRight) {
      controlsRight.start("visible");
      setHasAnimatedRight(true); // Set the state to prevent re-triggering
    }
  }, [controlsRight, inViewRight]);

  const boxVariantsRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  useEffect(() => {
    if (data) {
      seteventDetails(data);
    } else {
      getCatDetails();
    }
  }, []);

  const getCatDetails = async () => {
    setisLoading(true);
    await getCatDetail()
      .then((response) => {
        setisLoading(false);

        seteventDetails(response?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const onClickEvent = (e) => {
    navigate(`/ticket-details/${e?.location_id}/${e?.event_id}`);
  };

  return (
    <div
      className="binduz-er-sidebar-latest-post"
      style={{ marginTop: isSearch && "0px" }}
    >
      <div
        className="binduz-er-sidebar-title"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4 className="binduz-er-title">EVENTS</h4>
        <h2
          className="binduz-er-title"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/calender")}
        >{`CALENDER>`}</h2>
      </div>

      {!isLoading ? (
        <motion.div
          ref={refRight}
          initial="hidden"
          animate={controlsRight}
          variants={boxVariantsRight}
          className="binduz-er-sidebar-latest-post-box multi-color-border"
          style={{ paddingRight: "30px", paddingLeft: "30px",overflow:'scroll' ,height:'458px'}}
        >
          {eventDetails?.map((x) => {
            let fullAddress = `${x?.location?.address} ${x?.location?.city?.city} ${x?.location?.state?.state} ${x?.location?.zipcode}`;
            return (
              <EventCart
                title={x?.event?.name}
                date={formatDate(x?.date)}
                // location={x?.location?.address}
                // location={fullAddress}
                location={x?.location?.name}
                onClick={() => onClickEvent(x)}
                image={
                  x?.event?.flyer || x?.event?.sponsor_logo || x?.event?.image
                }
              />
            );
          })}
        </motion.div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default EventModule;
