import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import SidebarAbout from "../../containers/HomeOne/SidebarAbout";
import MessageModule from "../../containers/MessageModule";
import MegaPhoneSlider from "../../containers/MegaPhoneSlider";

function MainPostsSection({ bg }) {
  const navigate = useNavigate();
  let widthOfPage = window.innerWidth > 500;

  const [search, setsearch] = useState("");
  const [toggle, settoggle] = useState(false);
  const [megaPhoneSearch, setmegaPhoneSearch] = useState("");
  const [megaphoneToggle, setmegaphoneToggle] = useState(false);

  
  return (
    <>
      <section
        className="binduz-er-main-posts-area"
        style={{ backgroundColor: bg || "" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div
                className="binduz-er-top-news-title"
                style={{ paddingLeft: "40px" }}
              >
                <h3 className="binduz-er-title">
                  <i
                    className="far fa-envelope-square"
                    style={{ color: "red", marginRight: "5px" }}
                  ></i>
                  MESSAGE CENTER
                </h3>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: widthOfPage ? "" : "10px",
                  flexDirection: widthOfPage ? "row" : "column",
                }}
              >
                <div className="binduz-er-author-sidebar-search-bar col-lg-4">
                  <form
                    onSubmit={(e) => {
                      e?.preventDefault();
                      settoggle(!toggle);
                    }}
                  >
                    <div
                      className="binduz-er-input-box"
                      // style={{ width: "270px" }}
                    >
                      <input
                        type="text"
                        value={search}
                        onChange={(e) => setsearch(e?.target?.value)}
                        placeholder="Search from here..."
                        style={{ lineHeight: "38px" }}
                      />
                      <button type="submit" style={{ height: "40px" }}>
                        <i className="fal fa-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
                <a
                  className="classifield-text "
                  style={{
                    height: "40px",
                    marginLeft: "25px",
                    display: "flex",
                    marginRight: "43px",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    navigate("/message-center");
                  }}
                >
                  {"VIEW ALL >>"}
                </a>
              </div>
              <MessageModule toggle={toggle} search={search} />
            </div>
            <div className="col-lg-4">
              <div
                className="binduz-er-top-news-title"
                style={{ paddingLeft: "40px" }}
              >
                <h3 className="binduz-er-title d-flex"
                style={{justifyContent:'space-between'}}
                >
                  <div>
                    <i
                      className="far fa-bullhorn"
                      style={{ color: "red", marginRight: "5px" }}
                    ></i>
                    MEGAPHONE
                  </div>
                  <h2
                    className="classifield-text"
                    style={{
                      height: "40px",
                      // marginLeft: "20px",
                      display: "flex",
                      marginRight: '45px',
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={() => navigate("/megaphone-post")}
                  >{`POST +`}</h2>
                </h3>
              </div>

              <div style={{ display: "flex", justifyContent: "end" }}>
                <div className="binduz-er-author-sidebar-search-bar"></div>
              </div>

              <MegaPhoneSlider
                toggle={megaphoneToggle}
                search={megaPhoneSearch}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainPostsSection;
