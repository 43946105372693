import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { object, ref, string } from "yup";
import { changePassword } from "../../services/AuthServices";

const ChangePasswordModule = ({ setselectedTab }) => {
  const [isNewPassword, setisNewPassword] = useState(false);
  const [isPassword, setisPassword] = useState(false);
  const [isConfirmPassword, setisConfirmPassword] = useState(false);
  const [isDisable, setisDisable] = useState(false);

  const AdminSchema = object().shape({
    current_password: string()
      .required("Please enter current password.")
      .trim("Please enter current password."),

    new_password: string()
      .required("Please enter new password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "password must contain alphanumeric,uppercase,lowercase and special characters"
      )

      .notOneOf(
        [ref("current_password")],
        "New password and old password can't be same"
      ),

    new_password_confirmation: string()
      .required("Please confirm your new password") // .trim(t("Validation.ResetPassword.RequiredFiled") ?? "")
      .oneOf(
        [ref("new_password")],
        "New password and confirm password does not match"
      ),
  });

  const submitClicked = async (data) => {
    setisDisable(true);

    await changePassword(data)
      .then((response) => {
        setisDisable(false);

        toast("Password Changed Successfully", { type: "success" });
        setselectedTab(0);
      })
      .catch((error) => {
        setisDisable(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error?.response?.data?.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const { handleSubmit, handleBlur, handleChange, values, errors, touched } =
    useFormik({
      validationSchema: AdminSchema,

      initialValues: {
        current_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      onSubmit: async () => {
        let formdata = new FormData();
        formdata.append("current_password", values.current_password);
        formdata.append("new_password", values.new_password);
        formdata.append(
          "new_password_confirmation",
          values.new_password_confirmation
        );

        submitClicked(formdata);
      },
    });

  return (
    <div
      className="binduz-er-contact-us-box"
      style={{ marginTop: "10px", background: "none" }}
    >
      <form onSubmit={handleSubmit}>
        <div className="binduz-er-contact-title">
          <h1
            className="binduz-er-title"
            style={{ textAlign: "center", fontSize: "30px" }}
          >
            Change Password
          </h1>
        </div>
        <div
          className="row"
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            display: "flex",
            justifyContent: "center",
            // gap: "20px",
          }}
        >
          <div className="col-lg-8">
            <div className="binduz-er-input-box">
              <input
                type={isPassword ? "text" : "password"}
                placeholder="Current Password"
                name="current_password"
                id="current_password"
                value={values.current_password}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor:
                    touched?.current_password &&
                    errors?.current_password &&
                    "red",
                }}
              />
              {isPassword ? (
                <i
                  className="fal fa-eye"
                  onClick={() => setisPassword(false)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <i
                  className="fal fa-eye-slash"
                  style={{ cursor: "pointer" }}
                  onClick={() => setisPassword(true)}
                />
              )}
            </div>
            {touched?.current_password && errors?.current_password && (
              <span className="text-danger">{errors.current_password}</span>
            )}
          </div>

          <div className="col-lg-8">
            <div className="binduz-er-input-box">
              <input
                type={isNewPassword ? "text" : "password"}
                placeholder="New Password"
                name="new_password"
                id="new_password"
                value={values.new_password}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor:
                    touched?.new_password && errors?.new_password && "red",
                }}
              />
              {isNewPassword ? (
                <i
                  className="fal fa-eye"
                  onClick={() => setisNewPassword(false)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <i
                  className="fal fa-eye-slash"
                  style={{ cursor: "pointer" }}
                  onClick={() => setisNewPassword(true)}
                />
              )}{" "}
            </div>
            {touched?.new_password && errors?.new_password && (
              <span className="text-danger">{errors.new_password}</span>
            )}
          </div>

          <div className="col-lg-8">
            <div className="binduz-er-input-box">
              <input
                type={isConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                name="new_password_confirmation"
                id="new_password_confirmation"
                value={values.new_password_confirmation}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor:
                    touched?.new_password_confirmation &&
                    errors?.new_password_confirmation &&
                    "red",
                }}
              />
              {isConfirmPassword ? (
                <i
                  className="fal fa-eye"
                  onClick={() => setisConfirmPassword(false)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <i
                  className="fal fa-eye-slash"
                  style={{ cursor: "pointer" }}
                  onClick={() => setisConfirmPassword(true)}
                />
              )}{" "}
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.new_password_confirmation &&
              errors?.new_password_confirmation && (
                <span className="text-danger">
                  {errors.new_password_confirmation}
                </span>
              )}
          </div>
        </div>
        <button
          type="submit"
          disabled={isDisable}
          onClick={handleSubmit}
          className="binduz-er-main-btn contact-us-box-button mt-5"
        >
          {isDisable ? "Loading..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ChangePasswordModule;
