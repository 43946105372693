

import React, { useEffect, useState } from "react";
import ClassiFieldCart from "../../containers/PostCards/ClassiFieldCart";
import { getClassifieldCatData, getClassifieldPremiumData, getLatestClassifieldData } from "../../services/HomeServices";
import { toast } from "react-toastify";
import Loader from "../../containers/Loader";
import { motion, useAnimation, useViewportScroll } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { plainString } from "../../lib/constant";

const PremiumClassified = ({ bg }) => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [hasAnimatedLeft, setHasAnimatedLeft] = useState(false);
  const [hasAnimatedRight, setHasAnimatedRight] = useState(false);

  let widthOfPage = window.innerWidth > 700;

  const controlsLeft = useAnimation();
  const controlsRight = useAnimation();
  const { ref: refLeft, inView: inViewLeft } = useInView({ threshold: 0.1 });
  const { ref: refRight, inView: inViewRight } = useInView({ threshold: 0.1 });

  useEffect(() => {
   if (inViewLeft && !hasAnimatedLeft) {
     controlsLeft.start("visible");
     setHasAnimatedLeft(true); // Set the state to prevent re-triggering
   }
   if (inViewRight && !hasAnimatedRight) {
     controlsRight.start("visible");
     setHasAnimatedRight(true); // Set the state to prevent re-triggering
   }
  }, [controlsLeft, controlsRight, inViewLeft, inViewRight]);

  const boxVariantsLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  const boxVariantsRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setisLoading(true);
    await getClassifieldPremiumData()
      .then((response) => {
        setdata(response?.data?.data);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);

        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section className="binduz-er-main-posts-area  ">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* <div className="binduz-er-video-post-topbar">
              <div className="binduz-er-video-post-title">
                <h3 className="binduz-er-title">CLASSIFIEDS</h3>
              </div>
            </div> */}
            <div
              className="binduz-er-top-news-title"
              style={{
                display: widthOfPage ? "flex" : "",
                paddingLeft: "40px",
                paddingRight: "40px",
                justifyContent: "space-between",
              }}
            >
              <h3 className="binduz-er-title">
                <i
                  className="far fa-tasks"
                  style={{ color: "red", marginRight: "5px" }}
                ></i>
                CLASSIFIEDS
              </h3>
              <div className="d-lg-flex" style={{ gap: "7px" }}>
                <h2
                  className="classifield-text"
                  style={{
                    height: "40px",
                    // marginLeft: "25px",
                    display: "flex",
                    // margin-right: 43px;
                    marginTop: "20px",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() => navigate("/classifield-post")}
                >{`POST +`}</h2>
                <h2
                  className="classifield-text"
                  style={{
                    height: "40px",
                    // marginLeft: "25px",
                    display: "flex",
                    // margin-right: 43px;
                    marginTop: "20px",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() => navigate("/classifield")}
                >{`VIEW ALL >>`}</h2>
              </div>
            </div>

            <div
              className="row"
              style={{ paddingLeft: "60px", paddingRight: "60px" }}
            >
              {!isLoading ? (
                <>
                  {data?.map((x, i) => {
                    let isLeft = i > 3 && i < 8;
                    return (
                      <motion.div
                        className="col-lg-2"
                        // ref={isLeft ? refLeft : refRight}
                        // initial="hidden"
                        // animate={isLeft ? controlsLeft : controlsRight}
                        // variants={isLeft ? boxVariantsLeft : boxVariantsRight}
                        whileHover={{ scale: 1.05 }} // Scale up the image on hover
                        transition={{ type: "spring", stiffness: 300 }} // Smooth transition with spring effect
                      >
                        <div className="binduz-er-main-posts-item">
                          <ClassiFieldCart
                            index={i}
                            image={x?.images[0]?.image}
                            isPremium={true}
                            desc={plainString(x?.description)}
                            onClick={() => {
                              navigate(`/classifield-detail/${x?.id}`);
                            }}
                          />
                        </div>
                      </motion.div>
                    );
                  })}
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PremiumClassified;
