import React, { useEffect, useState } from "react";
import CardRowModule, {
  imgArray,
} from "../../containers/PostCards/CardRowModule";
import Pagination from "../../containers/Pagination";
import SideADModule from "../../containers/PostCards/SideADModule";
import { getMsgData } from "../../services/HomeServices";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import Loader from "../../containers/Loader";
import {
  formatDateToMMDDYYYY,
  formatDateToMMYYYY,
  plainString,
} from "../../lib/constant";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const formatDate = (date) => {
  const optionsDate = {
    weekday: "long",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const formattedDate = date?.toLocaleDateString("en-US", optionsDate);

  return `${formattedDate} `;
};

const MainSection = () => {
  const [messageData, setmessageData] = useState([]);
  const [selectedMonth, setselectedMonth] = useState(new Date());
  const [search, setsearch] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [links, setlinks] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getMessageData();
  }, [page]);

  useEffect(() => {
    setpage(1);
    getMessageData();
  }, [search, selectedMonth]);

  const getMessageData = async () => {
    setisLoading(true);
    await getMsgData(search, formatDateToMMYYYY(selectedMonth), page)
      .then((response) => {
        setmessageData(response?.data?.data?.data);
        setisLoading(false);
        setlinks(response?.data?.data?.links);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div
            className=" col-lg-9"
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">
                <i
                  className="far fa-envelope-square"
                  style={{ color: "red", marginRight: "5px" }}
                ></i>
                MESSAGE CENTER
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              className="col-lg-12"
            >
              <div className="row">
                <div className="col-lg-6">
                  <div className="date-picker">
                    <ReactDatePicker
                      dateFormat="MMMM yyyy"
                      autoComplete="off"
                      showMonthYearPicker
                      selected={selectedMonth}
                      onChange={(e) => {
                        setselectedMonth(e);
                      }}
                      name="date"
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="binduz-er-author-sidebar-search-bar col-lg-4">
                    <form
                      onSubmit={(e) => {
                        e?.preventDefault();
                        getMsgData();
                      }}
                    >
                      <div
                        className="binduz-er-input-box"
                        style={{ width: "270px" }}
                      >
                        <input
                          type="text"
                          onChange={(e) => setsearch(e?.target?.value)}
                          value={search}
                          placeholder="Search from here..."
                          style={{ lineHeight: "38px" }}
                        />
                        <button type="button" style={{ height: "40px" }}>
                          <i className="fal fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* {[3, 3, 3, 3, 3, 3, 3, 3].map((latestItem, index) => (
              <CardRowModule
                key={latestItem.id}
                datas={{
                  //   image: latestItem.post_images.latest_news[index],
                  index: index,
                  category: "Car",
                  title:
                    "CARFAX REPORT AND AUTOCHECK Report provided. Carfax report - $10.99 each VIN Autocheck report - $10.99 each VIN Both Reports - $17.99 Only Email: VaibhavReddy70@gmail.com Text: (971)808-2809",
                  date: "23-Feb",
                }}
              />
            ))}
            <Pagination /> */}
            {!isLoading ? (
              <>
                {!_.isEmpty(messageData) ? (
                  <>
                    {messageData?.map((item, index) => {
                      let profileImage;
                      if (item?.type === "assoiciation") {
                        profileImage = item?.user?.profile_image;
                      }
                      let imagePath = item?.image || profileImage;
                      return (
                        <CardRowModule
                          key={item.id}
                          datas={{
                            data: { ...item },
                            // image: item?.image,
                            image: imagePath,
                            category: item?.title,
                            index: index,
                            isTopStories: true,
                            isJobs: true,
                            imageSize: "130px",
                            pathname: `/ticket-details/${item?.id}`,
                            onClickFun: () => {
                              navigate(`/ticket-details/${item?.id}`, {
                                state: item,
                              });
                            },
                            title: item?.message,
                            date: formatDateToMMDDYYYY(
                              new Date(item?.created_at)
                            ),
                          }}
                        />
                      );
                    })}
                    <Pagination links={links} setpage={setpage} />
                  </>
                ) : (
                  <h5 style={{ textAlign: "center", marginTop: "60px" }}>
                    No Data found
                  </h5>
                )}
              </>
            ) : (
              <div style={{ marginTop: "80px" }}>
                <Loader />
              </div>
            )}
          </div>
          <div
            className=" col-lg-3"
            // style={{ paddingRight: "85px", paddingLeft: "55px" }}
          >
            {/* <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">EXPLORE YOUR COUNTRY</h3>
            </div>
            <div className="binduz-er-footer-gallery ">
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map((x,i) => (
                  <div className="">
                    <a href="#">
                      <img
                        height={85}
                        src={
                          imgArray[i]
                          // require(`../../assets/images/classifield.png`).default
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map((x,i) => (
                  <div className="">
                    <a href="#">
                      <img
                        height={85}
                        src={
                          // require(`../../assets/images/classifield.png`).default
                          imgArray[i]
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map((x,i) => (
                  <div className="">
                    <a href="#">
                      <img
                        height={85}
                        src={
                          // require(`../../assets/images/classifield.png`).default
                          imgArray[i]
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>

            <div
              className="binduz-er-footer-gallery "
              style={{ marginTop: "77px" }}
            >
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map((x,i) => (
                  <div className="binduz-er-item">
                    <a href="#">
                      <img
                        height={155}
                        width={165}
                        src={
                          // require(`../../assets/images/classifield.png`).default
                          imgArray[i+4]
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
              <div className="binduz-er-footer-gallery-widget d-flex">
                {[1, 1]?.map((x,i) => (
                  <div className="binduz-er-item">
                    <a href="#">
                      <img
                        height={155}
                        width={165}
                        src={
                          // require(`../../assets/images/classifield.png`).default
                          imgArray[i+2]
                        }
                        alt=""
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
             */}
            <SideADModule isAd={true} isClassifield={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
