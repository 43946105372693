import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from "yup";
import { toast } from "react-toastify";
import { dateSendToBackend, randomSecurityCode } from "../../lib/constant";
import {
  login,
  register,
  verifyEmailByOTP,
  verifyEmailOTP,
} from "../../services/AuthServices";
import OTPInput from "react-otp-input";

const MainSection = () => {
  const [isDisable, setisDisable] = useState(false);
  const [resendLoading, setresendLoading] = useState(true);
  const initialTime = 120;
  const location = useLocation();
  const registerData = location?.state;

  // State variables
  const [seconds, setSeconds] = useState(initialTime); // Seconds countdown
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      // Clean up the interval on component unmount or when the timer reaches zero
      return () => clearInterval(timerId);
    } else {
      setresendLoading(false); // Enable the resend button when the countdown reaches 0
    }
  }, [seconds]);

  const submitClicked = async (data) => {
    setisDisable(true);
    let formdata = new FormData();
    formdata.append("otp", data);
    formdata.append("email", registerData?.email);

    await verifyEmailOTP(formdata)
      .then((response) => {
        // setisDisable(false);
        // toast("Customer Register Successfully", { type: "success" });
        // navigate("/login");
        registerUser();
      })
      .catch((error) => {
        setisDisable(false);
        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error?.response?.data?.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const verifyEmail = async () => {
    setisDisable(true);
    let formdata = new FormData();
    formdata.append("email", registerData?.email);
    await verifyEmailByOTP(formdata)
      .then((response) => {
        setisDisable(false);

        toast("OTP successfully sent to your email", { type: "success" });
      })
      .catch((error) => {
        setisDisable(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const registerUser = async () => {
    setisDisable(true);
    let formdata = new FormData();
    formdata.append("firstName", registerData?.firstName);
    formdata.append("lastName", registerData?.lastName);
    formdata.append("email", registerData?.email);
    formdata.append("password", registerData?.password);
    formdata.append("company", registerData?.company);
    formdata.append("address", registerData?.address);
    formdata.append("address2", registerData?.address2);
    formdata.append("city", registerData?.city);
    formdata.append("state", registerData?.state);
    formdata.append("zip", registerData?.zip);
    formdata.append("phone", registerData?.phone);
    formdata.append("fax", registerData?.fax);
    formdata.append(
      "dateOfBirth",
      dateSendToBackend(registerData?.dateOfBirth)
    );
    formdata.append("logo", registerData?.logo);
    formdata.append(
      "isEmailSpeacialOffer",
      registerData?.isEmailSpeacialOffer ? 1 : 0
    );
    await register(formdata)
      .then((response) => {
        setisDisable(false);
        toast("Customer Register Successfully", { type: "success" });
        navigate("/login");
      })
      .catch((error) => {
        setisDisable(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    // validationSchema: AdminSchema,

    initialValues: {
      otp: "",
    },
    onSubmit: async () => {
      if (values?.otp?.length === 6 && Number(values?.otp)) {
        submitClicked(values?.otp);
      } else {
        toast("Please enter proper OTP", { type: "error" });
      }
    },

    onReset: () => {},
  });

  // useEffect(() => {

  //   if (values?.otp?.length === 6 && Number(values?.otp)) {
  //     setbuttonDisable(false);
  //   } else {
  //     setbuttonDisable(true);
  //   }
  // }, [values?.otp])

  const handleInputChange = (data) => {
    setFieldValue("otp", data);
  };

  const handleResend = () => {
    // Reset the timer back to 2 minutes
    verifyEmail();
    setSeconds(initialTime);
    setresendLoading(true);
  };

  // Format the time as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center", paddingTop: "140px" }}
    >
      <div className="binduz-er-contact-us-area ">
        <div className=" container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-lg-10 ">
              <div className="binduz-er-contact-us-box box-shadow d-lg-flex">
                <div
                  className=" col-lg-5 "
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ padding: "10px" }}>
                    <h6
                      className="binduz-er-title "
                      style={{ textAlign: "center" }}
                    >
                      You must validate your email address before registering!
                    </h6>
                    <div className="binduz-er-logo">
                      <a>
                        <img
                          src={
                            require("../../assets/images/az-logo.png").default
                          }
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className=" col-lg-7 ">
                  <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
                    <div
                      className="binduz-er-contact-title"
                      style={{ paddingTop: "15px" }}
                    >
                      <h1
                        className="binduz-er-title"
                        style={{ textAlign: "center", fontSize: "30px" }}
                      >
                        OTP Verification
                      </h1>
                    </div>
                    <div
                      className="row"
                      style={{
                        paddingTop: "15px",
                        paddingBottom: "15px",
                        gap: "20px",
                        minHeight: "200px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className=" col-lg-8">
                        <OTPInput
                          value={values?.otp}
                          onChange={handleInputChange}
                          numInputs={6}
                          isInputNum={true}
                          renderSeparator={<span>-</span>}
                          renderInput={(props) => <input {...props} />}
                          inputStyle="otp-input" // Custom class for input styling
                          focusStyle="otp-input-focus" // Class for focused input
                          containerStyle="otp-input-container" // Class for input container
                        />

                        <div
                          className="button-section"
                          style={{ justifyContent: "center" }}
                        >
                          <div>
                            {seconds !== 0 && (
                              <span
                                style={{
                                  color: "#e74d58",
                                }}
                              >
                                {formatTime(seconds)}
                              </span>
                            )}
                            <br />
                            <span
                              style={{
                                cursor: "pointer",
                                color: resendLoading ? "gray" : "#e74d58",
                                // textDecoration: "underline",
                              }}
                              onClick={!resendLoading && handleResend}
                              disabled={resendLoading}
                            >
                              Resend OTP
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      style={{ bottom: "-40px" }}
                      disabled={isDisable}
                      className="binduz-er-main-btn contact-us-box-button mt-5 "
                    >
                      {isDisable ? "Loading..." : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
