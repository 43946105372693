/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import DropDown from "../Helpers/DropDown";
import { toast } from "react-toastify";
import {
  getClassifieldCatData,
  getClassifieldSubCatData,
} from "../../services/HomeServices";
import ReactDatePicker from "react-datepicker";
import _, { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addClassifield,
  getAccountData,
  updateClassifield,
} from "../../services/AuthServices";
import {
  dateSendToBackend,
  formatDateToMMDDYYYY,
  formateMobileNumber,
  getDatefromBackend,
  plainString,
  validateNum,
} from "../../lib/constant";
import ImagePopUp from "../PostClassifield/ImagePopUp";

const imageManipulateArray = (array) => {
  let arr = [];
  for (let a of array) {
    arr?.push(process.env.REACT_APP_IMAGE_BASE_URL + a?.image);
  }
  return arr;
};

function imageUrlToFile(url, fileName) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Enable CORS

    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], fileName, { type: blob.type });
          resolve(file);
        } else {
          reject(new Error("Conversion to blob failed"));
        }
      }, "image/jpeg");
    };

    img.onerror = () => reject(new Error("Image load error"));
    img.src = url;
  });
}

async function imageUrlsToFileArray(imageUrls) {
  try {
    const filePromises = imageUrls.map((url, index) => {
      const fileName = `image-${index + 1}.jpg`; // Generate a unique filename
      return imageUrlToFile(url, fileName);
    });

    const filesArray = await Promise.all(filePromises);
    return filesArray;
  } catch (error) {
    console.error("Error converting images to files:", error);
  }
}

const ClassiFieldForm = () => {
  const [catList, setcatList] = useState([]);
  const [subCatList, setsubCatList] = useState([]);
  const [data, setdata] = useState({});
  const [isTrue, setisTrue] = useState(false);
  const [isDisable, setisDisable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let classifieldDetails = location?.state;
  const [selectedImage, setselectedImage] = useState(
    !_.isEmpty(classifieldDetails?.images)
      ? imageManipulateArray(classifieldDetails?.images)
      : // ? process.env.REACT_APP_IMAGE_BASE_URL + classifieldDetails?.image
        null
  );

  const fileInputRef = useRef(null);

  useEffect(() => {
    getCategory();
    getData();
    getSubCategory(classifieldDetails?.cate_id);
  }, []);

  const getData = async () => {
    await getAccountData()
      .then((response) => {
        setdata(response?.data?.data);
      })
      .catch((error) => {});
  };

  const getCategory = async () => {
    await getClassifieldCatData("")
      .then((response) => {
        setcatList(response?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getSubCategory = async (id) => {
    await getClassifieldSubCatData(id)
      .then((response) => {
        setsubCatList(response?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const AdminSchema = object().shape({
    email: string()
      .required("Please enter email.")
      .email("Invalid Email Format. Please try again.")
      .trim("Please enter email."),

    phone: string()
      .required("Please enter Mobile Number.")
      .trim("Please enter Mobile Number.")
      .test("phone", "Mobile number must be exactly 10 digits", (value) => {
        return validateNum(value);
      }),

    mobile: string().test(
      "mobile",
      "Alternative number must be exactly 10 digits",
      (value) => {
        if (value?.length > 0) {
          return validateNum(value);
        } else {
          return true;
        }
      }
    ),

    name: string().required("Please enter name.").trim("Please enter name."),

    // landmark: string()
    //   .required("Please enter landmark.")
    //   .trim("Please enter landmark."),

    contactDetail: string()
      .required("Please enter Contact Detail.")
      .trim("Please enter Contact Detail."),

    classiFieldDetails: string()
      .required("Please enter Classified Detail.")
      .trim("Please enter Classified Detail."),

    startDate: string()
      .required("Please enter Start Date.")
      .trim("Please enter Start Date."),

    categoryId: string()
      .required("Please enter Category.")
      .trim("Please enter Category."),

    // subCatId: string()
    //   .required("Please enter Sub Category.")
    //   .trim("Please enter Sub Category."),
  });

  const submitClicked = async (data) => {
    setisDisable(true);

    await updateClassifield(data)
      .then((response) => {
        setisDisable(false);
        toast("Classified Updated Successfully", { type: "success" });
        navigate("/account-detail");
      })
      .catch((error) => {
        setisDisable(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: AdminSchema,

    initialValues: {
      email: classifieldDetails?.email || "",
      name: classifieldDetails?.name || "",
      phone: formateMobileNumber(classifieldDetails?.phone_number) || "",
      contactDetail: classifieldDetails?.contact_detail || "",
      mobile: formateMobileNumber(classifieldDetails?.mobile) || "",
      categoryId: classifieldDetails?.cate_id || "",
      image: classifieldDetails?.image || null,
      landmark: classifieldDetails?.landmark || "",
      contact_person_name: classifieldDetails?.contact_person_name || "",
      subCatId: classifieldDetails?.sub_cate_id || "",
      classiFieldDetails: plainString(classifieldDetails?.description) || "",
      startDate: getDatefromBackend(classifieldDetails?.start_date) || "",
      showMyContact: false,
      isFree: classifieldDetails?.type === "standard" ? true : false,
      premiumImage: null,
    },
    onSubmit: async () => {
      let formdata = new FormData();
      values?.premiumImage.forEach((item, index) => {
        formdata.append(`premiumImage[${index}][image]`, item);
      });
      formdata.append("name", values.name);
      formdata.append("phone", values.phone);
      formdata.append("cate_id", values.categoryId);
      formdata.append("sub_Cate_id", values.subCatId);
      formdata.append("email", values.email);
      formdata.append("address", values.contactDetail);
      formdata.append("landmark", values.landmark);
      formdata.append("id", classifieldDetails.id);
      formdata.append("contact_person_name", values.contact_person_name);
      formdata.append("postal", values.zip);
      formdata.append("description", values.classiFieldDetails);
      formdata.append("type", values.isFree ? "Standard" : "Premium");
      formdata.append("date", dateSendToBackend(values.startDate));
      formdata.append("conatctdetail", values.contactDetail);
      formdata.append("mobile", values.mobile);
      // formdata.append("image", values.image);
      submitClicked(formdata);
    },

    onReset: () => {},
  });

  useEffect(() => {
    if (!_.isEmpty(classifieldDetails?.images)) {
      imageUrlsToFileArray(
        imageManipulateArray(classifieldDetails?.images)
      ).then((filesArray) => {
        setFieldValue("premiumImage", filesArray);
      });
    }
  }, []);

  const updateMyDetailsClicked = (value) => {
    if (value) {
      setFieldValue("email", data?.email);
      setFieldValue("name", data?.full_name);
      setFieldValue("phone", data?.phone_number);
      setFieldValue("contactDetail", data?.address);
      setFieldValue("landmark", data?.landmark);

      setisTrue(true);
    } else {
      setFieldValue("email", "");
      setFieldValue("name", "");
      setFieldValue("phone", "");
      setFieldValue("landmark", "");

      setFieldValue("contactDetail", "");
      setisTrue(false);
    }
  };

  const handleImageChange = (event, name) => {
    if (!_.isEmpty(selectedImage)) {
      setFieldValue("premiumImage", [
        ...values?.premiumImage,
        ...event?.target?.files,
      ]);
      // setFieldValue(name, file);

      let array = [];
      for (let a of [...values?.premiumImage, ...event?.target?.files]) {
        array?.push(URL.createObjectURL(a));
      }
      setselectedImage(array);
    } else {
      setFieldValue("premiumImage", [...event?.target?.files]);
      // setFieldValue(name, file);

      let array = [];
      for (let a of [...event?.target?.files]) {
        array?.push(URL.createObjectURL(a));
      }
      setselectedImage(array);
    }
  };

  const removeImage = (name) => {
    setFieldValue("premiumImage", null);
    // setFieldValue(name, null);
    setselectedImage(null);
    fileInputRef.current.value = "";
  };

  const selectedImageDeleteClicked = (index) => {
    let arr = [];
    let imgValueArr = [];

    if (selectedImage?.length === 1) {
      setIsOpen(false);
      removeImage();
    } else {
      for (let [i, a] of selectedImage?.entries()) {
        if (i !== index) {
          arr?.push(a);
        }
      }
      for (let [i, a] of values?.premiumImage?.entries()) {
        if (i !== index) {
          imgValueArr?.push(a);
        }
      }
      setFieldValue("premiumImage", imgValueArr);
      setselectedImage(arr);
      setIsOpen(false);
    }
  };

  const selectedImageAddClicked = () => {
    fileInputRef?.current?.click();
  };


  return (
    <div
      className="binduz-er-contact-us-box"
      style={{
        marginTop: "3px",
        background: "none",
      }}
    >
      <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
        <div
          className="row"
          style={{
            // paddingTop: "15px",
            paddingBottom: "15px",
            // gap: "20px",
          }}
        >
          <span style={{ marginBottom: "10px" }}>
            You can only post 4 classified post per category in a month.
          </span>
          <div>
            <h7 style={{ fontWeight: "700px" }}> Classified Type: </h7>
            <input
              className="m-2"
              type="radio"
              disabled
              id="standard"
              onChange={(e) => setFieldValue("isFree", !values?.isFree)}
              checked={values?.isFree}
            />
            <span>Standard (Free for 15 days) </span>
            <input
              className="m-2"
              type="radio"
              id="premium"
              disabled
              checked={!values?.isFree}
              onChange={(e) => setFieldValue("isFree", !values?.isFree)}
            />
            <span>Premium ($5 for 30 days) </span>
          </div>
          <div className="col-lg-4 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="Data"
                name="categoryId"
                id="categoryId"
                disabled
                onBlur={handleBlur}
                value={values?.categoryId}
                onChange={(e) => {
                  setFieldValue("categoryId", e?.target?.value);
                  getSubCategory(e?.target?.value);
                }}
                style={{
                  borderColor:
                    touched?.categoryId && errors?.categoryId && "red",
                }}
              >
                <option value="">Select Category</option>
                {catList?.map((x) => (
                  <option value={x?.id}>{x?.name}</option>
                ))}
              </select>

              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.categoryId && errors?.categoryId && (
              <span className="text-danger">{errors.categoryId}</span>
            )}
          </div>
          <div className="col-lg-4 ">
            <div className="binduz-er-input-box ">
              <select
                name="subCatId"
                onBlur={handleBlur}
                value={values?.subCatId}
                disabled
                style={{
                  borderColor: touched?.subCatId && errors?.subCatId && "red",
                }}
                onChange={(e) => {
                  setFieldValue("subCatId", e?.target?.value);
                }}
              >
                <option value="">Select Sub Category</option>
                {subCatList?.map((x) => (
                  <option value={x?.id}>{x?.name}</option>
                ))}
                {_.isEmpty(subCatList) && (
                  <option value={""}> No Data Found</option>
                )}
              </select>
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.subCatId && errors?.subCatId && (
              <span className="text-danger">{errors.subCatId}</span>
            )}
          </div>
          <div className="col-lg-4 ">
            <div className="binduz-er-input-box ">
              <ReactDatePicker
                placeholderText="Select Start Date"
                value={values?.startDate}
                minDate={new Date()}
                disabled
                autoComplete="off"
                onChange={(e) => {
                  setFieldValue("startDate", formatDateToMMDDYYYY(new Date(e)));
                }}
                onBlur={handleBlur}
                name="date"
              />
            </div>
            {touched?.startDate && errors?.startDate && (
              <span className="text-danger">{errors.startDate}</span>
            )}
          </div>
          <div
            className=" col-lg-12"
            style={{
              textAlign: "center",
              //  paddingTop: "100px"
            }}
          >
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  height: "180px",
                  resize: "auto",
                  borderColor:
                    touched?.classiFieldDetails &&
                    errors?.classiFieldDetails &&
                    "red",
                }}
                name="classiFieldDetails"
                id="classiFieldDetails"
                onChange={handleChange}
                maxLength={2000}
                onBlur={handleBlur}
                value={values?.classiFieldDetails}
                cols="5"
                rows="1"
                placeholder="Your Classified Details *"
              ></textarea>
              {touched?.classiFieldDetails && errors?.classiFieldDetails && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.classiFieldDetails}
                </div>
              )}
              <div
                style={{
                  textAlign: "start",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
              >
                Chars Remaining: {2000 - values?.classiFieldDetails?.length}
              </div>
            </div>
          </div>
          <div>
            <input
              className="mt-4"
              style={{ marginRight: "7px" }}
              type="checkbox"
              id="billingAddressCheckbox"
              // checked={isTrue}

              onChange={(e) => updateMyDetailsClicked(e?.target?.checked)}
            />
            <span>Update with my Contact details</span>
          </div>
          <div className="col-lg-12">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Address *"
                name="contactDetail"
                id="contactDetail"
                value={values.contactDetail}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor:
                    touched?.contactDetail && errors?.contactDetail && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.contactDetail && errors?.contactDetail && (
              <span className="text-danger">{errors.contactDetail}</span>
            )}
          </div>
          <div className="col-lg-12">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Landmark"
                name="landmark"
                id="landmark"
                value={values.landmark}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.landmark && errors?.landmark && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.landmark && errors?.landmark && (
              <span className="text-danger">{errors.landmark}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Name *"
                name="name"
                id="name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.name && errors?.name && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.name && errors?.name && (
              <span className="text-danger">{errors.name}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Email *"
                name="email"
                id="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.email && errors?.email && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.email && errors?.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Mobile Number *"
                name="phone"
                id="phone"
                value={values.phone}
                onBlur={handleBlur}
                // onChange={handleChange}
                onChange={(e) => {
                  setFieldValue("phone", formateMobileNumber(e?.target?.value));
                }}
                style={{
                  borderColor: touched?.phone && errors?.phone && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.phone && errors?.phone && (
              <span className="text-danger">{errors.phone}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Alternative Mobile Number "
                name="mobile"
                id="mobile"
                onChange={(e) => {
                  setFieldValue(
                    "mobile",
                    formateMobileNumber(e?.target?.value)
                  );
                }}
                value={values.mobile}
                onBlur={handleBlur}
                style={{
                  borderColor: touched?.mobile && errors?.mobile && "red",
                }}
                // onChange={handleChange}
              />
            </div>
            {touched?.mobile && errors?.mobile && (
              <span className="text-danger">{errors.mobile}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Contact Person Name"
                name="contact_person_name"
                id="contact_person_name"
                value={values.contact_person_name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
          </div>

          <div className="col-lg-6 ">
            <div className="binduz-er-input-box">
              <label htmlFor="image" className="custom-file-label">
                Choose Image
              </label>
              <input
                style={{ paddingLeft: "27px" }}
                type="file"
                placeholder="Logo *"
                accept="image/*"
                name="image"
                ref={fileInputRef}
                multiple
                id="image"
                onChange={(e) => handleImageChange(e, "image")}
                onBlur={handleBlur}
                className="form-control custom-file-input"
              />
            </div>
            {selectedImage && (
              <>
                <a
                  // href={selectedImage}
                  onClick={() => setIsOpen(true)}
                  // target="_blank"
                  className="m-1"
                >
                  <i className="fal fa-eye" style={{ cursor: "pointer" }} />
                </a>
                <a className="m-1" onClick={() => removeImage("image")}>
                  <i className="fal fa-trash" style={{ cursor: "pointer" }} />
                </a>
              </>
            )}
            <span>( Max Width/Height: 1150px * 500px )</span>
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={isDisable}
          className="binduz-er-main-btn contact-us-box-button mt-5"
        >
          {isDisable ? "Loading..." : "Submit"}
        </button>
      </form>
      <ImagePopUp
        images={selectedImage}
        fileInputRef={fileInputRef}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        deleteClicked={selectedImageDeleteClicked}
        addClicked={selectedImageAddClicked}
      />
    </div>
  );
};

export default ClassiFieldForm;
