import React, { useEffect, useState } from "react";
import VideoModule from "../../containers/VideoModule";
import img from "../../assets/images/azlogomessage.jpeg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getPodcast } from "../../services/HomeServices";
import { plainString } from "../../lib/constant";
import Loader from "../../containers/Loader";

const Podcast = () => {
  const [isLoading, setisLoading] = useState(false);
  const [data, setdata] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getPodcastData();
  }, []);

  const getPodcastData = async () => {
    setisLoading(true);
    await getPodcast()
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };
  

  return (
    <>
      <section
        className="binduz-er-main-posts-area"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="binduz-er-top-news-title"
                style={{ paddingLeft: "40px" }}
              >
                <h3 className="binduz-er-title">
                  <i
                    className="far fa-podcast"
                    style={{ color: "red", marginRight: "5px" }}
                  ></i>
                  PODCAST
                </h3>
              </div>
             {!isLoading? <div className="p-5 row">
                {data &&
                  data.length > 0 &&
                  data?.slice(0,8)?.map((obj, index) => (
                    <div key={obj.id + Math.random()} className="col-lg-3 ">
                      <div className="binduz-er-main-posts-item">
                        <VideoModule
                          video="true"
                          datas={{
                            video: "true",
                            link: obj?.link,
                            image: img,
                            category: obj?.category,
                            date: obj?.created_at,
                            title: obj?.title,
                          }}
                        >
                          <p className="line-clump-3">
                            {plainString(obj?.description)}
                          </p>
                        </VideoModule>
                      </div>
                    </div>
                  ))}
              </div>:
              <div style={{minHeight:'300px',display:'flex',alignItems:'center'}}>
                <Loader />
              </div>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Podcast;
