// BannerSlider.js
import React from "react";
import Slider from "react-slick";
import "./index.css"; // Import the CSS file
// import img from "/assets/images/azlogomessage.jpeg";
import img from "../../../../assets/images/azlogomessage.jpeg";

// Import Slick carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _, { isEmpty } from "lodash";

const BannerCarousal = ({ data }) => {
  const settings = {
    dots: true, // Enable dots navigation
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Enable arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="banner-slider">
      <Slider {...settings}>
        {/* Image Slide */}
        {!_.isEmpty(data) ? (
          data?.map((x) =>
            x?.isImage ? (
              <div className="banner-slider__item">
                <img
                  alt="Slide 1"
                  src={x?.image}
                  className="banner-slider__image"
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Darken the image (adjust opacity for intensity)
                  }}
                />
              </div>
            ) : (
              <div className="banner-slider__item">
                <video
                  src={
                    x?.image ||
                    "https://static.vecteezy.com/system/resources/previews/035/669/191/mp4/slow-motion-the-lights-flicker-from-the-music-stage-and-the-people-raising-their-hands-dancing-to-the-pulsating-music-of-this-music-festival-held-free-of-charge-in-the-public-park-of-asia-free-video.mp4"
                  }
                  // controls
                  className="banner-slider__video"
                  autoPlay
                  loop
                  muted
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Darken the image (adjust opacity for intensity)
                  }}
                />
              </div>
            )
          )
        ) : (
          <div className="banner-slider__item">
            <video
              src={
                "https://static.vecteezy.com/system/resources/previews/035/669/191/mp4/slow-motion-the-lights-flicker-from-the-music-stage-and-the-people-raising-their-hands-dancing-to-the-pulsating-music-of-this-music-festival-held-free-of-charge-in-the-public-park-of-asia-free-video.mp4"
              }
              // controls
              className="banner-slider__video"
              autoPlay
              loop
              muted
            />
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.2)", // Darken the image (adjust opacity for intensity)
              }}
            />
          </div>
        )}

        {/* Video Slide */}
      </Slider>
    </div>
  );
};

export default BannerCarousal;
