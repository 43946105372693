import React, { useEffect, useState } from "react";
import MainPostsContext from "../../contexts/HomeOne/MainPostsContext";
// import TrendingNewsContext from "../../contexts/HomeOne/TrendingNewsContext";
import navigationContext from "../../contexts/navigationsContext";
import { navigations } from "../../Data/navigation.json";
import { posts } from "../../Data/posts.json";
import useToggle from "../../Hooks/useToggle";
import BackToTop from "../Helpers/BackToTop";
import Drawer from "../Mobile/Drawer";
import Footer from "../Partials/Footers/HomeOne/Footer";
import HeaderOne from "../Partials/Headers/HeaderOne";
import Hero from "../Partials/Headers/HeaderOne/Hero";
import FeatureSection from "./FeatureSection";
import MainPostsSection from "./MainPostsSection";
// import TodayTrending from "./TodayTrending";
import TrendingSection from "./TrendingSection";
// import VideoPostsSection from "./VideoPostsSection";
// import SecondSection from "./SecondSection";
import TrendingNewsContext from "../../contexts/HomeOne/TrendingNewsContext";
import ClassiFields from "./ClassiFields";
import ClassiFieldsAd from "./ClassiFieldsAd";
import AD from "./AD";
import { motion } from "framer-motion";
import PremiumEvent from "./PremiumEvent";
import { toast } from "react-toastify";
import { getBannerIMG, getSponsorIMG } from "../../services/HomeServices";
import BannerCarousel from "../Partials/Headers/HeaderOne/BannerCarousel";
import { isImageOrVideo } from "../../lib/constant";
import PremiumClassified from "./PremiumClassified";
import Podcast from "./Podcast";

const HomeOne = () => {
  const navigationData = navigations;
  const [isLoading, setisLoading] = useState(false);
  const [drawer, setDrawer] = useToggle(false);
  const [bannerImage, setbannerImage] = useState([]);
  const [sponsorIMG, setsponsorIMG] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
    getBannerImage();
    getSponsorImage();
  }, []);

  const getBannerImage = async () => {
    setisLoading(true);
    await getBannerIMG()
      .then((response) => {
        const array =
          response?.data?.data?.map((a) => ({
            ...a,
            isImage: isImageOrVideo(a?.image) === "image",
          })) || [];

        setisLoading(false);
        setbannerImage(array);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const getSponsorImage = async () => {
    setisLoading(true);
    await getSponsorIMG()
      .then((response) => {
        // const array =
        //   response?.data?.data?.map((a) => ({
        //     ...a,
        //   })) || [];
        let array=[]
        for(let a of response?.data?.data){
          if(a?.above_classified==="1"){
            array?.push(a)
          }
        }
        setsponsorIMG(response?.data?.data);
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <motion.div
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
      style={{ overflow: "hidden" }}
    >
      <Drawer
        drawer={drawer}
        action={setDrawer.toggle}
        navigationData={navigationData}
      />
      <navigationContext.Provider value={{ navigationData }}>
        <HeaderOne drawerAction={setDrawer.toggle} />
      </navigationContext.Provider>
      {/* <Hero src={bannerImage[1]?.image} />
       */}
      <BannerCarousel data={bannerImage} />
      <MainPostsContext.Provider>
        <MainPostsSection />
      </MainPostsContext.Provider>
      <PremiumEvent />
      <Podcast />
      <TrendingNewsContext.Provider>
        <TrendingSection />
      </TrendingNewsContext.Provider>
      {/* <SecondSection /> */}
      <AD isBlack={true} data={sponsorIMG} />

      <FeatureSection />
      {/* <ClassiFieldsAd /> */}
      <AD data={sponsorIMG} />

      {/* <VideoPostsSection
        category={treandingNewsCategoryTab}
        videoPosts={videoPosts}
      />
      <TodayTrending todayTrending={todayTrending} /> */}

      <ClassiFields />
      <PremiumClassified />
      <Footer />
      <BackToTop />
    </motion.div>
  );
};

export default HomeOne;
