import React from "react";
import ReactPlayer from "react-player";

export default function VideoPopUp({ videoSrc, setValue }) {
  /* 
     //www.youtube.com/embed/EE7NqzhMDms?autoplay=1
    */
  return (
    <div>
      <div onClick={setValue} className="mfp-bg mfp-ready"></div>
      <div
        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-ready"
        tabIndex="-1"
        style={{ overflow: "hidden auto" }}
      >
        <div className="mfp-container mfp-s-ready mfp-iframe-holder">
          <div className="mfp-content">
            <div className="mfp-iframe-scaler">
              <button
                onClick={() => setValue(false)}
                title="Close (Esc)"
                type="button"
                className="mfp-close"
              >
                ×
              </button>
              {/* <iframe
                title="video"
                className="mfp-iframe"
                src={videoSrc}
                frameBorder="0"
                allowFullScreen=""
              /> */}
              <ReactPlayer
                style={{
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.4)",
                  borderRadius: "10px", // Apply border-radius to the ReactPlayer
                }}
                url={videoSrc}
                controls={true} // Show video controls like play, pause, etc.
                width="100%" // Adjust the width as per your need
                height="311px" // Adjust the height as per your need
              />
            </div>
          </div>
          <div className="mfp-preloader">Loading...</div>
        </div>
      </div>
    </div>
  );
}
