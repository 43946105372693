/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import DropDown from "../Helpers/DropDown";
import { toast } from "react-toastify";
import { getbussinessCategoryList } from "../../services/HomeServices";
import {
  addBussiness,
  getAccountData,
  getCityData,
  getStateData,
  updateBussiness,
} from "../../services/AuthServices";
import { useLocation, useNavigate } from "react-router-dom";
import { formateMobileNumber, plainString, validateNum } from "../../lib/constant";

const ClassiFieldForm = () => {
  const [catList, setcatList] = useState([]);
  const [stateData, setstateData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [profileData, setprofileData] = useState({});
  const [isDisable, setisDisable] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  let bussinessDetails = location?.state;
  const [selectedImage, setselectedImage] = useState(
    bussinessDetails?.image
      ? process.env.REACT_APP_IMAGE_BASE_URL + bussinessDetails?.image
      : null
  );
  useEffect(() => {
    getCategory();
    getData();
    getState();
  }, []);

  const getData = async () => {
    await getAccountData()
      .then((response) => {
        setprofileData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const getState = async () => {
    await getStateData()
      .then((response) => {
        setstateData(response?.data?.data);
        handeStateChange(bussinessDetails?.state_id, response?.data?.data);
      })
      .catch((error) => {});
  };

  const getCity = async (code) => {
    await getCityData(code)
      .then((response) => {
        setcityData(response?.data?.data);
      })
      .catch((error) => {});
  };

  const handeStateChange = (stateId, stateArray) => {
    setFieldValue("stateId", stateId);
    let code;
    let array = stateArray || stateData;

    for (let a of array) {
      if (a?.id === Number(stateId)) {
        code = a?.state_code;
      }
    }

    getCity(code);
  };

  const getCategory = async () => {
    await getbussinessCategoryList("")
      .then((response) => {
        setcatList(response?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const AdminSchema = object().shape({
    name: string().required("Please enter name.").trim("Please enter name."),
    address: string()
      .required("Please enter Address.")
      .trim("Please enter Address."),
    // landmark: string()
    //   .required("Please enter landmark.")
    //   .trim("Please enter landmark."),
    description: string()
      .required("Please enter description.")
      .trim("Please enter description."),
    url: string()
      .required("Please enter URL.")
      .trim("Please enter URL.")
      .matches(/^(https?:\/\/)/, "URL must start with http:// or https://"),
    categoryId: string()
      .required("Please enter category.")
      .trim("Please enter category."),
    // isMember: string()
    //   .required("Please enter Member.")
    //   .trim("Please enter Member."),
    phone: string()
      .required("Please enter Mobile Number.")
      .trim("Please enter Mobile Number.")
      .test("phone", "Mobile number must be exactly 10 digits", (value) => {
        return validateNum(value);
      }),
    stateId: string()
      .required("Please Select State.")
      .trim("Please Select State."),
    cityId: string()
      .required("Please Select City.")
      .trim("Please Select City."),
    zip: string()
      .required("Please enter zip.")
      .trim("Please enter zip.")
      .matches(/^\d{5}$/, "Must be exactly 5 digits"),
  });

  const submitClicked = async (data) => {
    setisDisable(true);
    await updateBussiness(data)
      .then((response) => {
        setisDisable(false);
        toast("Business Updated Successfully", { type: "success" });
        navigate("/local-bussiness");
      })
      .catch((error) => {
        setisDisable(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: AdminSchema,

    initialValues: {
      name: bussinessDetails?.name || "",
      address: bussinessDetails?.address || "",
      url: bussinessDetails?.url || "",
      phone: formateMobileNumber(bussinessDetails?.phone_number) || "",
      description: plainString(bussinessDetails?.description) || "",
      categoryId: bussinessDetails?.cate_id || "",
      image: bussinessDetails?.image || null,
      // isMember: bussinessDetails?.member === "member" ? 1 : 0,
      isFree: bussinessDetails?.type === "standard" ? true : false,
      stateId: bussinessDetails?.state_id || "",
      cityId: bussinessDetails?.city_id || "",
      zip: bussinessDetails?.zip_code || "",
      landmark: bussinessDetails?.landmark || "",
    },
    onSubmit: async () => {
      let formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("cate_id", values.categoryId);
      formdata.append("phone", values.phone);
      // formdata.append("member", values.isMember === 0 ? false : true);
      formdata.append("type", values.isFree ? "Standard" : "Premium");
      formdata.append("description", values.description);
      formdata.append("url", values.url);
      formdata.append("landmark", values.landmark);
      formdata.append("id", bussinessDetails?.id);
      formdata.append("address", values.address);
      formdata.append("postal", values.zip);
      formdata.append("state_id", values.stateId);
      formdata.append("image", values.image);
      formdata.append("city_id", values.cityId);
      submitClicked(formdata);
    },

    onReset: () => {},
  });

  const handleImageChange = (event, name) => {
    const file = event?.target?.files[0];
    setFieldValue(name, file);
    setselectedImage(URL.createObjectURL(file));
    const reader = new FileReader();
    reader.onloadend = () => {
      //  setImagePreview(reader?.result);
    };
    reader.readAsDataURL(file);
  };

  const removeImage = (name) => {
    setFieldValue(name, null);
    setselectedImage(null);
      fileInputRef.current.value = "";
  };

  return (
    <div
      className="binduz-er-contact-us-box"
      style={{
        marginTop: "3px",
        background: "none",
      }}
    >
      <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
        <div
          className="row"
          style={{
            // paddingTop: "15px",
            paddingBottom: "15px",
            // gap: "20px",
          }}
        >
          <div>
            <h7 style={{ fontWeight: "700px" }}> Local Businesses Type: </h7>
            <input
              className="m-2"
              type="radio"
              disabled
              id="standard"
              onChange={(e) => setFieldValue("isFree", !values?.isFree)}
              checked={values?.isFree}
            />
            <span>Standard (Free for 15 days) </span>
            <input
              className="m-2"
              disabled
              type="radio"
              id="premium"
              checked={!values?.isFree}
              onChange={(e) => setFieldValue("isFree", !values?.isFree)}
            />
            <span>Premium ($10 for 30 days) </span>
          </div>
          <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="Data"
                name="categoryId"
                onBlur={handleBlur}
                value={values?.categoryId}
                disabled
                onChange={handleChange}
                style={{
                  borderColor:
                    touched?.categoryId && errors?.categoryId && "red",
                }}
              >
                <option value="">Select Category</option>
                {catList?.map((x) => (
                  <option value={x?.id}>{x?.name}</option>
                ))}
              </select>
              {touched?.categoryId && errors?.categoryId && (
                <span className="text-danger">{errors.categoryId}</span>
              )}
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
          </div>
          {/* <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="Member"
                name="isMember"
                onBlur={handleBlur}
                disabled
                style={{
                  borderColor: touched?.isMember && errors?.isMember && "red",
                }}
                value={values?.isMember}
                onChange={handleChange}
              >
                <option value="">Select Member</option>
                <option value={1}>{"Member"}</option>
                <option value={0}>{"Non-Member"}</option>
              </select>
            </div>
            {touched?.isMember && errors?.isMember && (
              <span className="text-danger">{errors.isMember}</span>
            )}
          </div> */}
          <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="State"
                name="stateId"
                id="stateId"
                value={values.stateId}
                onBlur={handleBlur}
                // onChange={handleChange}
                onChange={(e) => handeStateChange(e?.target?.value)}
                style={{
                  borderColor: touched?.stateId && errors?.stateId && "red",
                }}
              >
                <option value="">Select State</option>
                {stateData?.map((x) => (
                  <option value={x?.id}>{x?.state}</option>
                ))}
              </select>
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.stateId && errors?.stateId && (
              <span className="text-danger">{errors.stateId}</span>
            )}
          </div>
          <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="City"
                name="cityId"
                onBlur={handleBlur}
                style={{
                  borderColor: touched?.cityId && errors?.cityId && "red",
                }}
                value={values?.cityId}
                onChange={handleChange}
              >
                <option value="">Select City</option>
                {cityData?.map((x) => (
                  <option value={x?.id}>{x?.city}</option>
                ))}
              </select>
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.cityId && errors?.cityId && (
              <span className="text-danger">{errors.cityId}</span>
            )}
          </div>
          <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <input
                type="number"
                placeholder="Zip *"
                name="zip"
                id="zip"
                value={values.zip}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.zip && errors?.zip && "red",
                }}
              />
              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.zip && errors?.zip && (
              <span className="text-danger">{errors.zip}</span>
            )}
          </div>
          <div
            className=" col-lg-12"
            style={{
              textAlign: "center",
              // , paddingTop: "100px"
            }}
          >
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  height: "98px",
                  resize: "auto",
                  borderColor: touched?.address && errors?.address && "red",
                }}
                name="address"
                id="address"
                onBlur={handleBlur}
                value={values?.address}
                maxLength={1200}
                onChange={handleChange}
                cols="3"
                rows="4"
                placeholder="Location Details *"
              ></textarea>
              {touched?.address && errors?.address && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.address}
                </div>
              )}
              <div
                style={{
                  textAlign: "start",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
              >
                Chars Remaining: {1200 - values?.address?.length}
              </div>
            </div>
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  height: "98px",
                  resize: "auto",
                  borderColor: touched?.landmark && errors?.landmark && "red",
                }}
                name="landmark"
                id="landmark"
                onBlur={handleBlur}
                value={values?.landmark}
                onChange={handleChange}
                cols="3"
                rows="4"
                placeholder="Landmark"
              ></textarea>
              {touched?.landmark && errors?.landmark && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.landmark}
                </div>
              )}
            </div>
          </div>

          <div className=" col-lg-12" style={{ textAlign: "center" }}>
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  // height: "98px",
                  resize: "auto",
                  borderColor:
                    touched?.description && errors?.description && "red",
                }}
                name="description"
                id="description"
                cols="3"
                value={values.description}
                maxLength={900}
                onBlur={handleBlur}
                onChange={handleChange}
                rows="4"
                placeholder="Description *"
              ></textarea>
              {touched?.description && errors?.description && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.description}
                </div>
              )}
              <div
                style={{
                  textAlign: "start",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
              >
                Chars Remaining: {900 - values?.description?.length}
              </div>
            </div>
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Name *"
                name="name"
                id="name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.name && errors?.name && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.name && errors?.name && (
              <span className="text-danger">{errors.name}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Mobile Number *"
                name="phone"
                id="phone"
                value={values.phone}
                onBlur={handleBlur}
                // onChange={handleChange}
                onChange={(e) => {
                  setFieldValue("phone", formateMobileNumber(e?.target?.value));
                }}
                style={{
                  borderColor: touched?.phone && errors?.phone && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.phone && errors?.phone && (
              <span className="text-danger">{errors.phone}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="URL *"
                name="url"
                id="url"
                value={values.url}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.url && errors?.url && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.url && errors?.url && (
              <span className="text-danger">{errors.url}</span>
            )}
          </div>

          <div className="col-lg-6 ">
            <div className="binduz-er-input-box">
              <label htmlFor="image" className="custom-file-label">
                Choose Image
              </label>
              <input
                type="file"
                placeholder="Logo *"
                accept="image/*"
                name="image"
                id="image"
                ref={fileInputRef}
                onChange={(e) => handleImageChange(e, "image")}
                onBlur={handleBlur}
                className="form-control custom-file-input"
              />
            </div>
            {selectedImage && (
              <>
                <a
                  // href={values?.image}
                  href={selectedImage}
                  target="_blank"
                  className="m-1"
                >
                  <i className="fal fa-eye" style={{ cursor: "pointer" }} />
                </a>
                <a className="m-1" onClick={() => removeImage("image")}>
                  <i className="fal fa-trash" style={{ cursor: "pointer" }} />
                </a>
              </>
            )}
            <span>( Max Width/Height: 1150px * 500px )</span>
          </div>
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={isDisable}
          className="binduz-er-main-btn contact-us-box-button mt-5"
        >
          {isDisable ? "Loading..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ClassiFieldForm;
