/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import DropDown from "../Helpers/DropDown";
import { dateSendToBackend, formatDateToMMDDYYYY, formateMobileNumber, validateNum } from "../../lib/constant";
import ReactDatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import {
  getJobsSubCatData,
  getPremiumAmount,
} from "../../services/HomeServices";
import { toast } from "react-toastify";
import { addJob, getAccountData } from "../../services/AuthServices";

const ClassiFieldForm = () => {
  const [catList, setcatList] = useState([]);
  const [data, setdata] = useState({});
  const [isTrue, setisTrue] = useState(false);
  const [premiumAmount, setpremiumAmount] = useState(0);
  const [isDisable, setisDisable] = useState(false);
    const [selectedImage, setselectedImage] = useState(null);
    const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getCategory();
    getData();
    getAmount();
  }, []);

 useEffect(() => {
   window.scrollTo({
     top: 0,
     behavior: "smooth", // Smooth scroll effect
   });
 }, []);
 
  const getAmount = async () => {
    await getPremiumAmount()
      .then((response) => {
        const event = response?.data?.data?.find((a) => a?.type === "Job");
        if (event) {
          setpremiumAmount(Number(event?.amount));
        }
      })
      .catch((error) => {});
  };

  const getData = async () => {
    await getAccountData()
      .then((response) => {
        setdata(response?.data?.data);
      })
      .catch((error) => {});
  };

  const getCategory = async () => {
    await getJobsSubCatData()
      .then((response) => {
        setcatList(response?.data?.data);
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const AdminSchema = object().shape({
    email: string()
      .required("Please Enter Email Address.")
      .email("Invalid Email Format. Please try again.")
      .trim("Please Enter Email Address."),

    phone: string()
      .required("Please enter Mobile Number.")
      .trim("Please enter Mobile Number.")
      .test("phone", "Mobile number must be exactly 10 digits", (value) => {
        return validateNum(value);
      }),
    mobile: string().test(
      "mobile",
      "Alternative number must be exactly 10 digits",
      (value) => {
        if (value?.length > 0) {
          return validateNum(value);
        } else {
          return true;
        }
      }
    ),

    sub_cate_id: string()
      .required("Please Select Category.")
      .trim("Please Select Category."),
    description: string()
      .required("Please enter description.")
      .trim("Please enter description."),

    date: string().required("Please Select Date.").trim("Please Select Date."),

    // company_name: string()
    //   .required("Please enter company name.")
    //   .trim("Please enter company name."),

    name: string().required("Please enter name.").trim("Please enter name."),
    contact_detail: string()
      .required("Please enter Contact Details.")
      .trim("Please enter Contact Details ."),

    // map_url: string().matches(
    //   /^(https?:\/\/)/,
    //   "URL must start with http:// or https://"
    // ),
  });

  const submitClicked = async (data) => {
    setisDisable(true);

    await addJob(data)
      .then((response) => {
        setisDisable(false);
        if (premiumAmount === 0) {
          toast("Job added Successfully", { type: "success" });
          navigate("/jobs");
        } else {
          response?.data?.data?.url
            ? window.location.replace(response?.data?.data?.url)
            : navigate("/jobs");
        }
      })
      .catch((error) => {
        setisDisable(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: AdminSchema,

    initialValues: {
      sub_cate_id: "",
      date: "",
      description: "",
      contact_detail: "",
      name: "",
      email: "",
      image: null,
      landmark: "",
      phone: "",
      mobile: "",
      location: "",
      map_url: "",
      isFree: false,
      company_name: "",
      amount: 0,
      show_my_contact: false,
    },
    onSubmit: async () => {
      let formdata = new FormData();
      formdata.append("name", values.name);
      formdata.append("phone", values.phone);
      formdata.append("sub_cate_id", values.sub_cate_id);
      formdata.append("email", values.email);
      formdata.append("description", values.description);
      formdata.append("date", dateSendToBackend(values.date));
      formdata.append("contact_detail", values.contact_detail);
      formdata.append("landmark", values.landmark);
      formdata.append("mobile", values.mobile);
      // formdata.append("map_url", values.map_url);
      formdata.append("location", values.location);
      formdata.append("show_my_contact", values.show_my_contact);
      formdata.append("company_name", values.company_name);
      formdata.append("type", "Premium");
      formdata.append("amount", premiumAmount);
      formdata.append("image", values.image);
      submitClicked(formdata);
    },

    onReset: () => {},
  });

  const updateMyDetailsClicked = (value) => {
    if (value) {
      setFieldValue("email", data?.email);
      setFieldValue("name", data?.full_name);
      setFieldValue("phone", data?.phone_number);
      setFieldValue("contactDetail", data?.address);
      setFieldValue("landmark", data?.landmark);

      setisTrue(true);
    } else {
      setFieldValue("email", "");
      setFieldValue("name", "");
      setFieldValue("phone", "");
      setFieldValue("contactDetail", "");
      setFieldValue("landmark", "");

      setisTrue(false);
    }
  };

  const handleImageChange = (event, name) => {
    const file = event?.target?.files[0];
    setFieldValue(name, file);
        setselectedImage(URL.createObjectURL(file));

    const reader = new FileReader();
    reader.onloadend = () => {
      //  setImagePreview(reader?.result);
    };
    reader.readAsDataURL(file);
  };

   const removeImage = (name) => {
     setFieldValue(name, null);
     setselectedImage(null);
     fileInputRef.current.value = "";
   };

  return (
    <div
      className="binduz-er-contact-us-box"
      style={{
        marginTop: "3px",
        background: "none",
      }}
    >
      <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
        <div
          className="row"
          style={{
            // paddingTop: "15px",
            paddingBottom: "15px",
            // gap: "20px",
          }}
        >
          {/* <h6 className="">
            
            <Link
              style={{ textDecoration: "underline", color: "red" }}
              // onClick={() => setModalIsOpen(true)}
            >
              Explore Premium!
            </Link>{" "}
          </h6> */}
          <div>
            <h7 style={{ fontWeight: "700px" }}> Job Type: </h7>

            <input className="m-2" type="radio" id="premium" checked={true} />
            <span>Premium (${premiumAmount} for 30 days) </span>
          </div>
          <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <select
                placeholder="Data"
                name="sub_cate_id"
                onBlur={handleBlur}
                value={values?.sub_cate_id}
                style={{
                  borderColor:
                    touched?.sub_cate_id && errors?.sub_cate_id && "red",
                }}
                onChange={(e) => {
                  setFieldValue("sub_cate_id", e?.target?.value);
                }}
              >
                <option value="">Select Category *</option>
                {catList?.map((x) => (
                  <option value={x?.id}>{x?.name}</option>
                ))}
              </select>
            </div>
            {touched?.sub_cate_id && errors?.sub_cate_id && (
              <span className="text-danger">{errors.sub_cate_id}</span>
            )}
          </div>
          <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <ReactDatePicker
                placeholderText="Select Start Date"
                autoComplete="off"
                minDate={new Date()}
                value={values?.date}
                onChange={(e) => {
                  setFieldValue("date", formatDateToMMDDYYYY(new Date(e)));
                }}
                onBlur={handleBlur}
                name="date"
              />

              {/* <span className="arrow-icon far fa-angle-down"></span> */}
            </div>
            {touched?.date && errors?.date && (
              <span className="text-danger">{errors.date}</span>
            )}
          </div>
          <div
            className=" col-lg-12"
            style={{
              textAlign: "center",
              //  paddingTop: "100px"
            }}
          >
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  height: "150px",
                  resize: "auto",
                  borderColor:
                    touched?.description && errors?.description && "red",
                }}
                name="description"
                id="description"
                maxLength={2000}
                value={values?.description}
                onChange={handleChange}
                onBlur={handleBlur}
                cols="3"
                rows="1"
                placeholder="Job Details *"
              ></textarea>
              {touched?.description && errors?.description && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.description}
                </div>
              )}
              <div
                style={{
                  textAlign: "start",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
              >
                Chars Remaining: {2000 - values?.description?.length}
              </div>
            </div>
          </div>
          <div>
            <input
              className="mt-4"
              style={{ marginRight: "7px" }}
              type="checkbox"
              id="billingAddressCheckbox"
              onChange={(e) => updateMyDetailsClicked(e?.target?.checked)}
            />
            <span>Update with my Contact details</span>
          </div>
          <div className="col-lg-12">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Contact Details *"
                name="contact_detail"
                id="contact_detail"
                value={values.contact_detail}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor:
                    touched?.contact_detail && errors?.contact_detail && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.contact_detail && errors?.contact_detail && (
              <span className="text-danger">{errors.contact_detail}</span>
            )}
          </div>
          <div className="col-lg-12">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Landmark"
                name="landmark"
                id="landmark"
                value={values.landmark}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.landmark && errors?.landmark && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.landmark && errors?.landmark && (
              <span className="text-danger">{errors.landmark}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Name *"
                name="name"
                id="name"
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.name && errors?.name && "red",
                }}
              />
            </div>
            {touched?.name && errors?.name && (
              <span className="text-danger">{errors.name}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Email *"
                name="email"
                id="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.email && errors?.email && "red",
                }}
              />
            </div>
            {touched?.email && errors?.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>

          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Mobile Number *"
                name="phone"
                id="phone"
                value={values.phone}
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("phone", formateMobileNumber(e?.target?.value));
                }}
                // onChange={handleChange}
                style={{
                  borderColor: touched?.phone && errors?.phone && "red",
                }}
              />
            </div>
            {touched?.phone && errors?.phone && (
              <span className="text-danger">{errors.phone}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Alternative Mobile Number "
                name="mobile"
                id="mobile"
                onChange={(e) => {
                  setFieldValue(
                    "mobile",
                    formateMobileNumber(e?.target?.value)
                  );
                }}
                value={values?.mobile}
                onBlur={handleBlur}
                // onChange={handleChange}
                style={{
                  borderColor: touched?.mobile && errors?.mobile && "red",
                }}
              />
            </div>
            {touched?.mobile && errors?.mobile && (
              <span className="text-danger">{errors.mobile}</span>
            )}
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                name="company_name"
                id="company_name"
                value={values?.company_name}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor:
                    touched?.company_name && errors?.company_name && "red",
                }}
                placeholder="Company Name "
              />
            </div>
            {touched?.company_name && errors?.company_name && (
              <span className="text-danger">{errors.company_name}</span>
            )}
          </div>
          {/* <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input type="text" placeholder="Pay * " />
            </div>
          </div> */}
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Location "
                name="location"
                id="location"
                value={values.location}
                onBlur={handleBlur}
                onChange={handleChange}
              />{" "}
            </div>
          </div>
          {/* <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input type="text" placeholder="Visa Type " />
            </div>
          </div> */}

          <div className="col-lg-6 ">
            <div className="binduz-er-input-box">
              <label htmlFor="image" className="custom-file-label">
                Choose Image
              </label>
              <input
                type="file"
                style={{ paddingLeft: "27px" }}
                placeholder="Image *"
                accept="image/*"
                name="image"
                id="image"
                ref={fileInputRef}
                // multiple
                // value={imagePreview}
                onChange={(e) => handleImageChange(e, "image")}
                onBlur={handleBlur}
                className="form-control custom-file-input"
              />
            </div>
            {selectedImage && (
              <>
                <a
                  // href={values?.image}
                  href={selectedImage}
                  target="_blank"
                  className="m-1"
                >
                  <i className="fal fa-eye" style={{ cursor: "pointer" }} />
                </a>
                <a className="m-1" onClick={() => removeImage("image")}>
                  <i className="fal fa-trash" style={{ cursor: "pointer" }} />
                </a>
              </>
            )}
            <span>( Max Width/Height: 1150px * 500px )</span>
          </div>
          {/* <div className=" col-lg-6">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Google Map URL"
                name="map_url"
                id="map_url"
                value={values.map_url}
                onBlur={handleBlur}
                style={{
                  borderColor: touched?.map_url && errors?.map_url && "red",
                }}
                onChange={handleChange}
              />{" "}
            </div>
            <span
              style={{
                color: touched?.map_url && errors?.map_url ? "red" : "",
              }}
            >{` (URL must have http:// or https:// )`}</span>
          </div> */}
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={isDisable}
          className="binduz-er-main-btn contact-us-box-button mt-5"
        >
          {isDisable ? "Loading..." : "Proceed to payment"}
        </button>
      </form>
    </div>
  );
};

export default ClassiFieldForm;
