import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { plainString } from "../../lib/constant";
import SideADModule from "../../containers/PostCards/SideADModule";
import { toast } from "react-toastify";
import { getDocotrDetail } from "../../services/HomeServices";

const NewDoctorPage = () => {
  const location = useLocation();
  const [data, setdata] = useState(location?.state);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
    // getData()
  }, []);

  const getData = async () => {
    setisLoading(true);
    await getDocotrDetail()
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "My Website",
          text: "Check out this awesome content!",
          url: window.location.href,
        });
        console.log("Content shared successfully");
      } catch (err) {
        console.error("Error sharing the content:", err);
      }
    } else {
      alert("Sharing is not supported on this browser.");
    }
  };

  return (
    <section
      className="binduz-er-author-item-area pb-20"
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div className="row">
          <div className=" col-lg-9">
            <div
              className="binduz-er-author-item mb-40"
              style={{ borderRadius: "10px" }}
            >
              {data?.image && (
                <div className="binduz-er-thumb">
                  <img src={data?.image} alt="" />
                </div>
              )}
              <div className="binduz-er-meta-list d-flex">
                <ul
                  className="d-flex mt-3"
                  style={{ gap: "30px", listStyleType: "none" }}
                >
                  {/* <li>
                    <i className="fal fa-eye"></i> 5k
                  </li> */}
                  <li style={{ cursor: "pointer" }} onClick={handleShare}>
                    <i className="fal fa-share"></i> Share
                  </li>
                </ul>
              </div>

              <div className="binduz-er-blog-details-box">
                <div
                  className="d-lg-flex"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <h4 className="binduz-er-title">{data?.name}</h4>
                    <div
                      className="binduz-er-text"
                      style={{ fontWeight: "500" }}
                    >
                      <p style={{ margin: "0px" }}>
                        {plainString(data?.address)}
                      </p>
                      <p style={{ margin: "0px" }}>
                        {" "}
                        {data?.city?.city} {data?.state?.state}
                      </p>
                      <p style={{ margin: "0px" }}>{data?.zip_code}</p>
                    </div>
                    {data?.email && data?.email !== "0" && (
                      <div className="col-lg-12 mt-1">
                        <p className="card-text text-muted">
                          <strong>Email :</strong>{" "}
                          <span style={{ color: "#e74d58" }}>
                            {data?.email}
                          </span>
                        </p>
                      </div>
                    )}
                    {data?.phone && (
                      <div className="col-lg-12 mt-1">
                        <p className="card-text text-muted">
                          <strong>Mobile Number :</strong>{" "}
                          <span style={{ color: "#e74d58" }}>
                            {data?.phone}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    {data?.profile_image && (
                      <div className=" col-lg-12 ">
                        <div
                          className="binduz-er-blog-details-thumb mt-25"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{ maxHeight: "200px", maxWidth: "200px" }}
                            className="image-shadow"
                            src={data?.profile_image}
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="binduz-er-text mt-50">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.comment,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" col-lg-3">
            <SideADModule isEvent={true} isClassifield={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewDoctorPage;
