import React, { useEffect } from "react";
import Loader from "../../containers/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getBussinessPaymentSuccess,
  getClassifieldPaymentSuccess,
  getDoctorPaymentSuccess,
  getEventPaymentSuccess,
  getJobPaymentSuccess,
  getMegaphonePaymentSuccess,
  renewAPIforBussiness,
  renewAPIforClassified,
  renewAPIforEvent,
  renewAPIforJob,
  renewAPIforMegaphone,
} from "../../services/AuthServices";

const MainSection = () => {
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (location?.pathname === "/classifield/payment-success") {
      getSuccessAPIforClassifield();
    } else if (location?.pathname === "/local-bussiness/payment-success") {
      getSuccessAPIforBussiness();
    } else if (location?.pathname === "/doctor/payment-success") {
      getSuccessAPIforDoctor();
    } else if (location?.pathname === "/event/payment-success") {
      getSuccessAPIforEvent();
    } else if (location?.pathname === "/job/payment-success") {
      getSuccessAPIforJob();
    } else if (location?.pathname === "/job/payment-success/renew") {
      getRenewAPIforJob();
    } else if (location?.pathname === "/megaphone/payment-success") {
      getSuccessAPIforMegaphone();
    } else if (location?.pathname === "/megaphone/payment-success/renew") {
      getRenewAPIforMegaphone();
    } else if (location?.pathname === "/classifield/payment-success/renew") {
      getRenewAPIforClassified();
    } else if (location?.pathname === "/event/payment-success/renew") {
      getRenewAPIforEvent();
    } else if (
      location?.pathname === "/local-bussiness/payment-success/renew"
    ) {
      getRenewAPIforBussiness();
    }
  }, []);

  const getSuccessAPIforClassifield = async () => {
    await getClassifieldPaymentSuccess(true)
      .then((response) => {
        toast("Payment Successfull", { type: "success" });
        navigate("/account-detail");
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getSuccessAPIforEvent = async () => {
    await getEventPaymentSuccess(true)
      .then((response) => {
        toast("Payment Successfull", { type: "success" });
        navigate("/account-detail");
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getSuccessAPIforBussiness = async () => {
    await getBussinessPaymentSuccess(true)
      .then((response) => {
        toast("Payment Successfull", { type: "success" });
        navigate("/account-detail");
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getSuccessAPIforDoctor = async () => {
    await getDoctorPaymentSuccess(true)
      .then((response) => {
        toast("Payment Successfull", { type: "success" });
        navigate("/account-detail");
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getSuccessAPIforJob = async () => {
    await getJobPaymentSuccess(true)
      .then((response) => {
        toast("Payment Successfull", { type: "success" });
        navigate("/account-detail");
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getSuccessAPIforMegaphone = async () => {
    await getMegaphonePaymentSuccess(true)
      .then((response) => {
        toast("Payment Successfull", { type: "success" });
        navigate("/account-detail");
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getRenewAPIforEvent = async () => {
    await renewAPIforEvent(true)
      .then((response) => {
        toast("Payment Successfull", { type: "success" });
        navigate("/account-detail");
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getRenewAPIforClassified = async () => {
    await renewAPIforClassified(true)
      .then((response) => {
        toast("Payment Successfull", { type: "success" });
        navigate("/account-detail");
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getRenewAPIforJob = async () => {
    await renewAPIforJob(true)
      .then((response) => {
        toast("Payment Successfull", { type: "success" });
        navigate("/account-detail");
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };
  const getRenewAPIforBussiness = async () => {
    await renewAPIforBussiness(true)
      .then((response) => {
        toast("Payment Successfull", { type: "success" });
        navigate("/account-detail");
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  const getRenewAPIforMegaphone = async () => {
    await renewAPIforMegaphone(true)
      .then((response) => {
        toast("Payment Successfull", { type: "success" });
        navigate("/account-detail");
      })
      .catch((error) => {
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <div className="payment-success">
      <div className="payment-success__icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="green"
          width="96px"
          height="96px"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 0C5.37 0 0 5.37 0 12s5.37 12 12 12 12-5.37 12-12S18.63 0 12 0zm-1 17.93l-4.24-4.24 1.41-1.41 2.83 2.83 6.59-6.59 1.41 1.41L11 17.93z" />
        </svg>
      </div>
      <h1 className="payment-success__title">PAYMENT SUCCESSFUL</h1>
      <p className="payment-success__message">
        Thank you for your payment. Your transaction has been completed
        successfully. <br /> we will be in contact with more details shortly
      </p>
      {/* <button
        className="payment-success__button"
        onClick={() => (window.location.href = "/")}
      >
        Go to Homepage
      </button> */}
    </div>
  );
};

export default MainSection;
