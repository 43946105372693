import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getClassifieldDetail } from "../../services/HomeServices";
import Loader from "../../containers/Loader";
import { formatDate, isImageOrVideo } from "../../lib/constant";
import SideADModule from "../../containers/PostCards/SideADModule";
import { motion } from "framer-motion";
import BannerImage from "../Partials/Headers/HeaderOne/BannerImage";
import BannerCarousel from "../Partials/Headers/HeaderOne/BannerCarousel";
import _ from "lodash";

const MainSection = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    getData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [params]);

  const getData = async () => {
    setisLoading(true);
    await getClassifieldDetail(params?.id)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const manipulateArray = (arr) => {
    const array =
      arr?.map((a) => ({
        ...a,
        isImage: isImageOrVideo(a?.image) === "image",
      })) || [];
    return array;
  };

  return (
    <>
      {!_.isEmpty(data?.images) && (
        <BannerCarousel data={manipulateArray(data?.images)} />
      )}
      <section
        className={`binduz-er-latest-news-area `}
        style={{ paddingTop: "30px" }}
      >
        <div className=" container">
          <div className="row">
            <motion.div
              className=" col-lg-9"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 1 }}
              transition={{ duration: 4 }}
              style={{ paddingRight: "40px", paddingLeft: "40px" }}
            >
              <div className="binduz-er-top-news-title">
                <h3 className="binduz-er-title">CLASSIFIED DETAIL</h3>
              </div>

              {!isLoading ? (
                <>
                  {/* {data?.image && (
                  // <div
                  //   style={{
                  //     marginTop: "40px",
                  //     display: "flex",
                  //     // justifyContent: "center",
                  //     // alignItems: "center",
                  //   }}
                  // >
                  <div className="binduz-er-thumb col-lg-3 mt-5">
                    <img
                      className="image-shadow"
                      src={data?.image}
                      // height={300}
                      alt=""
                    />
                  </div>
                  // </div>
                )} */}
                  {/* <div className="classifield-details-text mt-4">
                    <div className="col-lg-12">
                      <p className="card-text text-muted">
                        <strong>Name :</strong>{" "}
                        <span style={{ color: "#e74d58" }}>{data?.name}</span>
                      </p>
                    </div>
                    {data?.contact_person_name && (
                      <div className="col-lg-12">
                        <p className="card-text text-muted">
                          <strong>Contact Person Name :</strong>{" "}
                          <span style={{ color: "#e74d58" }}>
                            {data?.contact_person_name}
                          </span>
                        </p>
                      </div>
                    )}
                    <div className="col-lg-12">
                      <p className="card-text text-muted">
                        <strong>Mobile Number :</strong>{" "}
                        <span style={{ color: "#e74d58" }}>
                          {data?.phone_number}
                        </span>
                      </p>
                    </div>
                    {data?.mobile && (
                      <div className="col-lg-12">
                        <p className="card-text text-muted">
                          <strong>Alternative Mobile Number :</strong>{" "}
                          <span style={{ color: "#e74d58" }}>
                            {data?.mobile}
                          </span>
                        </p>
                      </div>
                    )}
                    <div className="col-lg-12">
                      <p className="card-text text-muted">
                        <strong>Email :</strong>{" "}
                        <span style={{ color: "#e74d58" }}>{data?.email}</span>
                      </p>
                    </div>
                    <div className="col-lg-12">
                      <p className="card-text text-muted">
                        <strong>Posted On :</strong>{" "}
                        <span style={{ color: "#e74d58" }}>
                          {formatDate(new Date(data?.start_date))}
                        </span>
                      </p>
                    </div>
                    {data?.url && (
                      <div className="col-lg-12">
                        <p className="card-text text-muted">
                          <strong>Web :</strong>{" "}
                          <span style={{ color: "#e74d58" }}>{data?.url}</span>
                        </p>
                      </div>
                    )}
                  </div> */}

                  <div class="contact-info mb-2 mt-4">
                    {data?.name && (
                      <p>
                        <span class="icon">👤</span>Name:{" "}
                        <span>{data?.name}</span>
                      </p>
                    )}
                    {data?.contact_person_name && (
                      <p>
                        <span class="icon">🏢</span>Contact Person Name:{" "}
                        <span> {data?.contact_person_name}</span>
                      </p>
                    )}
                    <p>
                      <span class="icon">📞</span>Mobile Number:{" "}
                      <span>{data?.phone_number}</span>
                    </p>
                    {data?.mobile && (
                      <p>
                        <span class="icon">📞</span>Alternative Mobile Number:{" "}
                        <span>{data?.mobile}</span>
                      </p>
                    )}
                    <p>
                      <span class="icon">✉️</span>Email:{" "}
                      <a
                        style={{ color: "#e74d58", fontWeight: "bold" }}
                        href={`mailto:${data?.email}`}
                      >
                        {data?.email}
                      </a>
                    </p>

                    <p>
                      <span class="icon">📅</span>Posted On:{" "}
                      <span> {formatDate(new Date(data?.start_date))}</span>
                    </p>
                    {data?.url && (
                      <p>
                        <span class="icon">💻</span>Web:{" "}
                        <span>{data?.url}</span>
                      </p>
                    )}
                  </div>

                  <div className="classifield-details-text">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.description,
                      }}
                    />
                  </div>

                  <div className="binduz-er-title">
                    <p>
                      When you call dont forget to mention you found this ad
                      from azindia.com
                      <br />
                    </p>
                  </div>
                </>
              ) : (
                <div style={{ marginTop: "101px" }}>
                  <Loader />
                </div>
              )}
            </motion.div>
            <div
              className=" col-lg-3"
              // style={{ paddingRight: "85px", paddingLeft: "55px" }}
            >
              <SideADModule isAd={false} isClassifield={true} isEvent={true} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainSection;
