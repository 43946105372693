import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { object, string } from "yup";
import { updateProfile } from "../../services/AuthServices";
import {
  dateSendToBackend,
  formatDateToMMDDYYYY,
  formateMobileNumber,
  getDatefromBackend,
  validateNum,
} from "../../lib/constant";
import _ from "lodash";

const AccountDetailEdit = ({ stateData, cityData, data, getCity }) => {
  // const [imagePreview, setImagePreview] = useState(null);
  const [isDisable, setisDisable] = useState(false);
  const [selectedImage, setselectedImage] = useState(
    data?.profile_image
      ? process.env.REACT_APP_IMAGE_BASE_URL + data?.profile_image
      : null
  );
  const fileInputRef = useRef(null);

  const AdminSchema = object().shape({
    email: string()
      .required("Please enter email.")
      .email("Invalid Email Format. Please try again.")
      .trim("Please enter email."),

    firstName: string()
      .required("Please enter first name.")
      .trim("Please enter first name."),
    lastName: string()
      .required("Please enter last name.")
      .trim("Please enter last name."),
    // company: string()
    //   .required("Please enter company.")
    //   .trim("Please enter company."),
    address: string()
      .required("Please enter address.")
      .trim("Please enter address."),

    // landmark: string()
    //   .required("Please enter landmark.")
    //   .trim("Please enter landmark."),

    city: string().required("Please enter city.").trim("Please enter city."),
    state: string().required("Please enter state.").trim("Please enter state."),
    zip: string()
      .required("Please enter zip.")
      .trim("Please enter zip.")
      .matches(/^\d{5}$/, "Must be exactly 5 digits"),
    phone: string()
      .required("Please enter Mobile number.")
      .trim("Please enter Mobile number.")
      .test("phone", "Mobile number must be exactly 10 digits", (value) => {
        return validateNum(value);
      }),

    // dateOfBirth: string()
    //   .required("Please enter your date of birth.")
    //   .trim("Please enter your date of birth."),
  });

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setDataToProfile(data);
    }
  }, []);

  const submitClicked = async (data) => {
    setisDisable(true);

    await updateProfile(data)
      .then((response) => {
        setisDisable(false);

        toast("Account Details updated Successfully", { type: "success" });
      })
      .catch((error) => {
        setisDisable(false);

        if (error?.response?.status == 422)
          toast(error?.response?.data?.message, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: AdminSchema,

    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      landmark: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      logo: "",
      isEmailSpeacialOffer: true,
      dateOfBirth: "",
    },
    onSubmit: async () => {
      let formdata = new FormData();
      formdata.append("firstName", values.firstName);
      formdata.append("lastName", values.lastName);
      formdata.append("email", values.email);
      formdata.append("landmark", values.landmark);
      formdata.append("company", values.company);
      formdata.append("address", values.address);
      formdata.append("city_id", values.city);
      formdata.append("state_id", values.state);
      formdata.append("postal", values.zip);
      formdata.append("phone", values?.phone);
      formdata.append("dateOfBirth", dateSendToBackend(values.dateOfBirth));
      formdata.append("logo", values?.logo);
      formdata.append(
        "isEmailSpeacialOffer",
        values.isEmailSpeacialOffer ? 1 : 0
      );
      submitClicked(formdata);
    },
  });

  const handeStateChange = (stateId) => {
    setFieldValue("state", stateId);
    setFieldValue("city", "");
    let code;
    for (let a of stateData) {
      if (a?.id == Number(stateId)) {
        code = a?.state_code;
      }
    }
    getCity(code);
  };

  const setDataToProfile = (profileData) => {
    setFieldValue("email", profileData?.email || "");
    setFieldValue("isSiteUpdate", profileData?.is_get_site_update || false);
    setFieldValue(
      "phone",
      formateMobileNumber(profileData?.phone_number) || ""
    );
    setFieldValue("dateOfBirth", getDatefromBackend(profileData?.dob) || "");
    setFieldValue("firstName", profileData?.first_name || "");
    setFieldValue("lastName", profileData?.last_name || "");
    setFieldValue("company", profileData?.company_name || "");
    setFieldValue("address", profileData?.address || "");
    setFieldValue("city", profileData?.city_id || "");
    setFieldValue("state", profileData?.state_id || "");
    setFieldValue("zip", profileData?.zip_code || "");
    setFieldValue("landmark", profileData?.landmark || "");
    setFieldValue("logo", profileData?.profile_image || "");
    setFieldValue(
      "isEmailSpeacialOffer",
      profileData?.isEmailSpeacialOffer === "1" ? true : false 
    );
    // setFieldValue(
    //   "isEmailSpeacialOffer",
    //   profileData?.is_get_email_spacial_offers  || true
    // );
    // setFieldValue(
    //   "isNewsLetter",
    //   profileData?.is_get_monthly_newsletter || false
    // );
  };
  const handleImageChange = (event) => {
    const file = event?.target?.files[0];
    setFieldValue("logo", file);
    setselectedImage(URL.createObjectURL(file));

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // setImagePreview(e.target.result);
      };
      reader.readAsText(file);
    }
  };

  const removeImage = (name) => {
    setFieldValue(name, null);
    fileInputRef.current.value = "";
    setselectedImage(null);
  };


  return (
    <div
      className="binduz-er-contact-us-box"
      style={{ marginTop: "10px", background: "none" }}
    >
      <form onSubmit={handleSubmit}>
        <div className="binduz-er-contact-title">
          <h1
            className="binduz-er-title"
            style={{ textAlign: "center", fontSize: "30px" }}
          >
            Account Details
          </h1>
        </div>
        <div
          className="row"
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            // gap: "20px",
          }}
        >
          <div className="col-lg-4">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Email"
                name="email"
                id="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.email && errors?.email && "red",
                }}
              />
            </div>
            {touched?.email && errors?.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>

          <div className="col-lg-4">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="First Name"
                name="firstName"
                id="firstName"
                value={values.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.firstName && errors?.firstName && "red",
                }}
              />
            </div>
            {touched?.firstName && errors?.firstName && (
              <span className="text-danger">{errors.firstName}</span>
            )}
          </div>

          <div className="col-lg-4">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Last Name"
                name="lastName"
                id="lastName"
                value={values.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.lastName && errors?.lastName && "red",
                }}
              />
              {/* <i className="fal fa-user"></i> */}
            </div>
            {touched?.lastName && errors?.lastName && (
              <span className="text-danger">{errors.lastName}</span>
            )}
          </div>

          <div className="col-lg-4">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Company"
                name="company"
                id="company"
                value={values.company}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.company && errors?.company && "red",
                }}
              />
            </div>
            {touched?.company && errors?.company && (
              <span className="text-danger">{errors.company}</span>
            )}
          </div>

          <div className="col-lg-4">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Mobile Number"
                name="phone"
                id="phone"
                value={values.phone}
                onBlur={handleBlur}
                // onChange={handleChange}
                onChange={(e) => {
                  setFieldValue("phone", formateMobileNumber(e?.target?.value));
                }}
                style={{
                  borderColor: touched?.phone && errors?.phone && "red",
                }}
              />
            </div>
            {touched?.phone && errors?.phone && (
              <span className="text-danger">{errors.phone}</span>
            )}
          </div>
          <div className="col-lg-4">
            <div className="binduz-er-input-box" style={{ zIndex: 100 }}>
              <ReactDatePicker
                placeholderText="Date Of Birth"
                value={values?.dateOfBirth}
                autoComplete="off"
                maxDate={new Date()}
                onChange={(e) => {
                  setFieldValue(
                    "dateOfBirth",
                    formatDateToMMDDYYYY(new Date(e))
                  );
                }}
                name="dateOfBirth"
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100} // Show
                showMonthDropdown
              />{" "}
            </div>
            {touched?.dateOfBirth && errors?.dateOfBirth && (
              <span className="text-danger">{errors.dateOfBirth}</span>
            )}
          </div>

          <div className="col-lg-12">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Address"
                name="address"
                id="address"
                value={values.address}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.address && errors?.address && "red",
                }}
              />
            </div>
            {touched?.address && errors?.address && (
              <span className="text-danger">{errors.address}</span>
            )}{" "}
          </div>

          <div className="col-lg-12">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Landmark"
                name="landmark"
                id="landmark"
                value={values.landmark}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.landmark && errors?.landmark && "red",
                }}
              />
            </div>
            {touched?.landmark && errors?.landmark && (
              <span className="text-danger">{errors.landmark}</span>
            )}{" "}
          </div>

          <div className="col-lg-6">
            <div className="binduz-er-input-box">
              <select
                placeholder="Data"
                name="state"
                id="state"
                value={values.state}
                onBlur={handleBlur}
                // onChange={handleChange}
                onChange={(e) => handeStateChange(e?.target?.value)}
                style={{
                  borderColor: touched?.state && errors?.state && "red",
                }}
              >
                <option value="">State</option>
                {stateData?.map((x) => (
                  <option value={x?.id}>{x?.state}</option>
                ))}
              </select>
            </div>
            {touched?.state && errors?.state && (
              <span className="text-danger">{errors?.state}</span>
            )}
          </div>

          <div className="col-lg-6">
            <div className="binduz-er-input-box">
              <select
                placeholder="City"
                name="city"
                id="city"
                value={values.city}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.city && errors?.city && "red",
                }}
              >
                <option value="">City</option>
                {cityData?.map((x) => (
                  <option value={x?.id}>{x?.city}</option>
                ))}
              </select>{" "}
            </div>
            {touched?.city && errors?.city && (
              <span className="text-danger">{errors?.city}</span>
            )}
          </div>

          <div className="col-lg-6">
            <div className="binduz-er-input-box">
              <input
                type="number"
                placeholder="Zip"
                name="zip"
                id="zip"
                value={values.zip}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.zip && errors?.zip && "red",
                }}
              />
            </div>
            {touched?.zip && errors?.zip && (
              <span className="text-danger">{errors.zip}</span>
            )}
          </div>

          {/* <div
            className="col-lg-12 mt-5"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h6
              style={{
                margin: "0px",
                marginRight: "7px",
                marginLeft: "7px",
              }}
            >
              Logo:
            </h6>
            {values?.logo && (
              <a
                href={process.env.REACT_APP_IMAGE_BASE_URL + values?.logo}
                target="_blank"
                className="m-1"
              >
                <i className="fal fa-eye" style={{ cursor: "pointer" }} />
              </a>
            )}
            <input
              type="file"
              accept="image/*"
              placeholder="Logo *"
              name="logo"
              id="logo"
              onBlur={handleBlur}
              onChange={(e) => handleImageChange(e)}
            />
          </div> */}

          <div className="col-lg-6">
            <div className="binduz-er-input-box">
              <label htmlFor="logo" className="custom-file-label">
                Choose Image
              </label>
              <input
                style={{ paddingLeft: "27px" }}
                type="file"
                placeholder="Logo *"
                accept="image/*"
                name="logo"
                ref={fileInputRef}
                id="logo"
                onChange={(e) => handleImageChange(e, "logo")}
                onBlur={handleBlur}
                className="form-control custom-file-input"
              />
            </div>
            {selectedImage && (
              <>
                <a
                  // href={values?.image}
                  href={selectedImage}
                  target="_blank"
                  className="m-1"
                >
                  <i className="fal fa-eye" style={{ cursor: "pointer" }} />
                </a>
                <a className="m-1" onClick={() => removeImage("logo")}>
                  <i className="fal fa-trash" style={{ cursor: "pointer" }} />
                </a>
              </>
            )}
          </div>

          <div>
            <input
              className="mt-4"
              style={{ marginRight: "7px" }}
              type="checkbox"
              id="billingAddressCheckbox"
              name="isEmailSpeacialOffer"
              onBlur={handleBlur}
              onChange={handleChange}
              checked={values?.isEmailSpeacialOffer}
            />
            <span>Receive Emails Monthly Newsletter & special offers</span>
          </div>
          {/* <div>
            <input
              className=""
              style={{ marginRight: "7px" }}
              type="checkbox"
              id="isNewsLetter"
              name="isNewsLetter"
              onBlur={handleBlur}
              onChange={handleChange}
              checked={values?.isNewsLetter}
            />
            <span>Receive Monthly Newsletter</span>
          </div>
          <div>
            <input
              className=""
              style={{ marginRight: "7px" }}
              type="checkbox"
              id="isMovieReview"
              name="isMovieReview"
              onBlur={handleBlur}
              onChange={handleChange}
              checked={values?.isMovieReview}
            />
            <span>Receive Emails when new movie reviews are added</span>
          </div>
          <div>
            <input
              className=""
              style={{ marginRight: "7px" }}
              type="checkbox"
              id="isSiteUpdate"
              name="isSiteUpdate"
              onBlur={handleBlur}
              onChange={handleChange}
              checked={values?.isSiteUpdate}
            />
            <span>Receive Site Updates (Once every 3 Months)</span>
          </div> */}
        </div>
        <button
          type="submit"
          disabled={isDisable}
          onClick={handleSubmit}
          className="binduz-er-main-btn contact-us-box-button mt-5"
        >
          {isDisable ? "Loading..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default AccountDetailEdit;
