import React from "react";
import { Link, useNavigate } from "react-router-dom";
import img from "../../assets/images/azlogomessage.jpeg";
import img1 from "../../assets/images/az1.jpeg";
import img2 from "../../assets/images/az2.jpeg";
import img3 from "../../assets/images/az3.jpeg";
import img4 from "../../assets/images/az4.jpeg";
import img5 from "../../assets/images/adazindia.jpeg";
import { motion, useAnimation } from "framer-motion";

export const imgArray = [img, img1, img2, img3, img4, img5];
const onClick = () => {};
const CardRowModule = ({
  datas = {
    data: {},
    isJobs: false,
    image: "",
    category: "",
    title: "",
    date: "",
    pathname: "",
    imageSize:"",
    isTopStories: false,
    index: "0",
    author: "",
    to: "",
    state: {},
    onClickFun: onClick,
  },
}) => {
  const navigate = useNavigate("");
  return (
    // <div className="binduz-er-latest-news-item">
    //   <div className="binduz-er-thumb">
    //     <img src={datas?.image || img} alt="" />
    //   </div>
    //   <div
    //     className="binduz-er-content"
    //     onClick={() => {
    //       if (!datas?.isTopStories) {
    //         navigate("/classifield-detail");
    //       }
    //     }}
    //   >
    //     {datas?.category && (
    //       <div className="classifield-text">
    //         <span>{datas?.category}</span>
    //       </div>
    //     )}
    //     <h5 className="binduz-er-title line-clump-2">
    //       <Link
    //         to={{
    //           pathname: datas?.pathname,
    //         }}
    //         state={datas?.data}
    //       >
    //         <div
    //           dangerouslySetInnerHTML={{
    //             __html: datas?.title,
    //           }}
    //         />
    //       </Link>
    //     </h5>
    //     <div className="binduz-er-meta-item">
    //       <div className="binduz-er-meta-author">
    //         {!datas?.isTopStories ? (
    //           <span>
    //             By <span>{datas?.author}</span>
    //           </span>
    //         ) : (
    //           <span
    //             style={{ cursor: "pointer" }}
    //             onClick={datas?.onClickFun}
    //           >
    //             <span>{"View Details>"}</span>
    //           </span>
    //         )}
    //       </div>
    //       <div className="binduz-er-meta-date">
    //         <span>
    //           <i className="fal fa-calendar-alt"></i> {datas?.date}
    //         </span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <motion.div
      whileHover={{ scale: 1.05 }} // Scale up the image on hover
      transition={{ type: "spring", stiffness: 300 }} // Smooth transition with spring effect
      className="card event-card mt-4 mb-4"
      style={{ borderRadius: "20px" }}
    >
      <div className="card-body d-flex align-items-center p-0">
        {/* <div
          className="col-lg-2"
          style={{ textAlign: "center", marginRight: "4px" }}
        > */}
        <img
          src={datas?.image || img}
          alt="AZIndia Logo"
          style={{
            top: "0%",
            display: "inline-block",
            objectFit: "fill",
            height: datas?.imageSize||'100px',
            left: "0%",
            marginRight: "25px",
          }}
          className="event-card-img col-lg-2"
        />
        {/* </div> */}
        <div className="event-card-content col-lg-10">
          {datas?.category && (
            <div className="classifield-text" style={{ marginTop: "12px" }}>
              <span>{datas?.category}</span>
            </div>
          )}
          <div className="binduz-er-title line-clump-2">
            <Link
              to={{
                pathname: datas?.pathname,
              }}
              state={datas?.data}
            >
              <div
                style={{
                  marginRight: "50px",
                }}
                dangerouslySetInnerHTML={{
                  __html: datas?.title,
                }}
              />
            </Link>
          </div>
          <div
            className="d-flex justify-content-between align-items-center event-card-footer"
            style={{ paddingBottom: "10px" }}
          >
            {/* {!datas?.isTopStories && (
              <div className="binduz-er-meta-author">
                <span
                  style={{ cursor: "pointer", color: "#e74d58" }}
                  onClick={() => {
                    navigationActive(item);
                  }}
                >
                  {"View Details>"}
                </span>
              </div>
            )} */}

            {datas?.isTopStories ? (
              <div className="binduz-er-meta-author">
                <span
                  style={{ cursor: "pointer", color: "#e74d58" }}
                  onClick={datas?.onClickFun}
                >
                  {"View Details>"}
                </span>
              </div>
            ) : (
              <div className="binduz-er-meta-author">
                <span>
                  By <span>{datas?.author}</span>
                </span>
              </div>
            )}
            <div
              className="binduz-er-meta-date"
              style={{ paddingRight: "40px" }}
            >
              <span>
                <i
                  className="fal fa-calendar-alt"
                  style={{ marginRight: "7px" }}
                ></i>
                {datas?.date}
              </span>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CardRowModule;
