import React, { useCallback, useEffect, useState } from "react";
import Pagination from "../../containers/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { getReligiousPlacesData } from "../../services/HomeServices";
import { toast } from "react-toastify";
import Loader from "../../containers/Loader";
import _ from "lodash";
import { motion, useAnimation } from "framer-motion";
import SideADModule from "../../containers/PostCards/SideADModule";

const MainSection = () => {
  const [data, setdata] = useState([]);
  const [search, setsearch] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [links, setlinks] = useState([]);

  useEffect(() => {
    getData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, [page]);

  useEffect(() => {
    setpage(1);
    getData();
  }, [search]);

  const getData = async () => {
    setisLoading(true);

    await getReligiousPlacesData(search, page)
      .then((response) => {
        setisLoading(false);
        setlinks(response?.data?.data?.links);
        setdata(response?.data?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <section
      className={`binduz-er-latest-news-area `}
      style={{ paddingTop: "30px" }}
    >
      <div className=" container">
        <div
          className="row"
          // style={{ paddingRight: "40px", paddingLeft: "40px" }}

          // style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            className=" col-lg-9"
            style={{ paddingRight: "40px", paddingLeft: "40px" }}
          >
            <div className="binduz-er-top-news-title">
              <h3 className="binduz-er-title">
                <i
                  className="far fa-place-of-worship"
                  style={{ color: "red", marginRight: "5px" }}
                ></i>
                RELIGIOUS PLACES
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              className="col-lg-12"
            >
              <div className="row">
                <div className="col-lg-4">
                  <div className="binduz-er-author-sidebar-search-bar col-lg-4">
                    <form
                      onSubmit={(e) => {
                        e?.preventDefault();
                        getData();
                      }}
                    >
                      <div
                        className="binduz-er-input-box"
                        style={{ width: "270px" }}
                      >
                        <input
                          type="text"
                          onChange={(e) => setsearch(e?.target?.value)}
                          value={search}
                          placeholder="Search from here..."
                          style={{ lineHeight: "38px" }}
                        />
                        <button type="submit" style={{ height: "40px" }}>
                          <i className="fal fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {!isLoading ? (
              <>
                {!_.isEmpty(data) ? (
                  <div className="row" style={{display:'flex',justifyContent:'center'}}>
                    {data?.map((item, index) => (
                      <motion.div
                        whileHover={{ scale: 1.05 }} // Scale up the image on hover
                        transition={{ type: "spring", stiffness: 300 }} // Smooth transition with spring effect
                        className="card event-card mt-4 col-lg-5 "
                        style={{ borderRadius: "20px",marginRight:'50px' }}
                      >
                        <div
                          className="card-body d-flex align-items-center "
                          style={{ padding: "25px" }}
                        >
                          <div className="event-card-content col-lg-12">
                            {item?.name && (
                              <div
                                className="classifield-text"
                                style={{ cursor: "default" }}
                              >
                                <span>{item?.name}</span>
                              </div>
                            )}
                            <div className="binduz-er-title line-clump-2">
                              <Link>
                                <div
                                  style={{ cursor: "default" }}
                                  dangerouslySetInnerHTML={{
                                    __html: item?.address,
                                  }}
                                />
                              </Link>
                            </div>
                            <h6 style={{ margin: "0px" }}>
                              Mobile Number:- {item.phone_number}
                            </h6>

                            <a
                              className="line-clump-3"
                              style={{ cursor: "pointer" }}
                              href={item?.url}
                              target="_blank"
                            >
                              {item.url}
                            </a>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                    <div className="mt-3">
                      <Pagination links={links} setpage={setpage} />
                    </div>
                  </div>
                ) : (
                  <h5 style={{ textAlign: "center" }}>No Data found</h5>
                )}
              </>
            ) : (
              <div style={{ marginTop: "80px" }}>
                <Loader />
              </div>
            )}
          </div>
          <div
            className=" col-lg-3"
            // style={{ paddingRight: "85px", paddingLeft: "55px" }}
          >
            <SideADModule isEvent={true} isClassifield={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
