import React, { useEffect, useState } from "react";
import SliderCom from "../Helpers/SliderCom";
import img from "../../assets/images/azlogomessage.jpeg";
import img1 from "../../assets/images/az1.jpeg";
import img2 from "../../assets/images/az2.jpeg";
import img3 from "../../assets/images/az3.jpeg";
import img4 from "../../assets/images/az4.jpeg";
import img5 from "../../assets/images/adazindia.jpeg";
import { motion, useAnimation } from "framer-motion";
import "./index.css";
import { useInView } from "react-intersection-observer";

export const imgArray = [
  img,
  img1,
  img2,
  img3,
  img4,
  img5,
  img4,
  img3,
  img,
  img3,
  img2,
  img5,
];

const AD = ({ isBlack,data }) => {
  const [hasAnimatedRight, setHasAnimatedRight] = useState(false);
  // const [isFlipped, setIsFlipped] = useState(false);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    speed: 1300,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const controlsRight = useAnimation();
  const { ref: refRight, inView: inViewRight } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewRight && !hasAnimatedRight) {
      controlsRight.start("visible");
      setHasAnimatedRight(true); // Set the state to prevent re-triggering
    }
  }, [controlsRight, inViewRight]);

  const boxVariantsRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };
  return (
    <div style={{ backgroundColor: isBlack && "#000" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <motion.div
              className="binduz-er-blog-related-post-slide"
              style={{ padding: "30px" }}
              ref={refRight}
              initial="hidden"
              animate={controlsRight}
              variants={boxVariantsRight}
            >
              <SliderCom settings={settings}>
                {data?.map((x) => (
                  <motion.div
                    className="binduz-er-video-post bounce-ad"
                    style={{ padding: "0px", perspective: "1000px" }} // Add perspective for a 3D effect
                    whileHover={{
                      scale: 1.05, // Enlarge the image slightly on hover
                      rotateY: 180, // Flip the image on Y-axis
                      x: 20, // Move the image slightly ahead
                      transition: {
                        duration: 0.6, // Smooth and slower transition
                        ease: "easeInOut", // Smooth easing for the animation
                      },
                    }}
                  >
                    <div
                      className="binduz-er-latest-news-item"
                      style={{ padding: "0px" }}
                    >
                      <div
                        className="binduz-er-thumb"
                        style={{ borderRadius: "5px" }}
                      >
                        <img
                          src={x?.image} // Use your image source here
                          alt="Flipping Image"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
                    </div>
                  </motion.div>
                ))}
              </SliderCom>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AD;
