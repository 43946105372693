import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import "./Calender.css";
import { formatDateToMMDDYYYY } from "../../lib/constant";
import EventPopup from "./EventPopup";


const MyCustomEvent = ({ event }) => {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
    >
      <span>{event?.title}</span>
      {showPopup && (
        <div
          className="popup-modal"
          style={{
            background: event?.color,
          }}
        >
          <strong>{event?.title}</strong>
          <br />
          <span>Location:- {event?.location?.address}</span>
          <br />
          <span>Date:- {formatDateToMMDDYYYY(event?.date)}</span>
        </div>
      )}
    </div>
  );
};

export default function FullCalender({
  data,
  selectedMonth,
  setselectedMonth,
}) {
  const [eventData, setEventData] = useState([]);
  const [defaultDate, setDefaultDate] = useState();
const [isPopupOpen, setPopupOpen] = useState(false);
const [popupEvents, setPopupEvents] = useState([]);
const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

  const navigate = useNavigate();
  const localizer = momentLocalizer(moment);

  const addHours = (dateString, hoursToAdd) => {
    const originalDate = new Date(dateString);
    originalDate.setHours(originalDate.getHours() + hoursToAdd);
    return originalDate;
  };

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  useEffect(() => {
    if (!_.isEmpty(data)) {
      let array = [];
      for (let a of data) {
        let date = new Date(`${a?.date} ${a?.time}`);
        let obj = {
          ...a,
          title: a?.event?.name,
          start: date,
          end: addHours(date, 2),
          color: getRandomColor(),
        };
        array.push(obj);
      }
      setEventData(array);
    }
  }, [data]);

  useEffect(() => {
    if (!_.isEmpty(selectedMonth)) {
      let selected = `01/${selectedMonth}`;
      let changeDateFormat = selected.split(/\//);
      let defaultDate = [
        changeDateFormat[1],
        changeDateFormat[0],
        changeDateFormat[2],
      ].join("/");
      setDefaultDate(new Date(defaultDate));
    }
  }, [selectedMonth]);

  const onClickEvent = (e) => {
    navigate(`/ticket-details/${e?.location_id}/${e?.event_id}`);
  };

  const handleDateHoverEnd = (e) => {
    setDefaultDate(new Date(e));
    setselectedMonth(e);
  };

 const handleShowMore = (events, date, e) => {
  //  const rect = e?.target?.getBoundingClientRect();
   setPopupEvents(events);
  //  setPopupPosition({ x: rect.left, y: rect.top });
   setPopupOpen(true);
 };
  return (
    <div style={{ position: "relative" }}>
      <Calendar
        views={[ "month"]}
        localizer={localizer}
        events={eventData}
        date={defaultDate}
        onNavigate={handleDateHoverEnd}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={(event) => onClickEvent(event)}
        // showAllEvents={true}
        style={{ height: 750, margin: "10px", marginTop: "30px" }}
       
        onShowMore={(events,date,e)=>{
          handleShowMore(events,date,e)
        }

        }
        components={{
          event: MyCustomEvent,
        }}
        eventPropGetter={(event) => {
          return {
            style: {
              backgroundColor: event?.color,
              color: "white",
              borderRadius: "4px",
              border: "none",
            },
          };
        }}
      />
      <EventPopup
        isOpen={isPopupOpen}
        onClose={() => setPopupOpen(false)}
        events={popupEvents}
        position={popupPosition}
      />
    </div>
  );
}
