import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { object, string } from "yup";
import { toast } from "react-toastify";
import { randomSecurityCode } from "../../lib/constant";
import { forgotPassword, login } from "../../services/AuthServices";

const MainSection = () => {
  const [securityCode, setSecurityCode] = useState("");
  const [isDisable, setisDisable] = useState(false);

  // const [errorMessage, seterrorMessage] = useState("")

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
    setSecurityCode(randomSecurityCode());
  }, []);

  const AdminSchema = object().shape({
    email: string()
      .required("Please enter email.")
      .email("Invalid Email Format. Please try again.")
      .trim("Please enter email."),
  });

  const submitClicked = async (data) => {
    setisDisable(true);

    let formdata = new FormData();
    formdata.append("email", values.email);
    formdata.append("password", values.password);

    await forgotPassword(formdata)
      .then((response) => {
        setisDisable(false);
        toast("Reset password link sent successfully to your email", {
          type: "success",
        });
        navigate("/login");
      })
      .catch((error) => {
        setisDisable(false);
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error?.response.data?.message, { type: "error" });
        else
          toast(error?.response?.data?.message || "Something went wrong", {
            type: "error",
          });
      });
  };

  const { handleSubmit, handleBlur, handleChange, values, errors, touched } =
    useFormik({
      validationSchema: AdminSchema,

      initialValues: {
        email: "",
        code: "",
      },
      onSubmit: async () => {
        if (values?.code === Number(securityCode)) {
          submitClicked(values);
        } else {
          toast("Security code does not match.", {
            type: "error",
          });
        }
      },

      onReset: () => {},
    });

  return (
    <div
      style={{ display: "flex", justifyContent: "center", paddingTop: "140px" }}
    >
      <div className="binduz-er-contact-us-area">
        <div className=" container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="   col-lg-10">
              <div className="binduz-er-contact-us-box box-shadow">
                <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
                  <div
                    className="binduz-er-contact-title"
                    style={{ paddingTop: "15px" }}
                  >
                    <h1
                      className="binduz-er-title"
                      style={{ textAlign: "center", fontSize: "30px" }}
                    >
                      FORGOT PASSWORD
                    </h1>
                  </div>
                  <div
                    className="row"
                    style={{
                      paddingTop: "5px",
                      paddingBottom: "35px",
                      gap: "10px",
                      justifyContent: "center",
                    }}
                  >
                    <div className=" col-lg-8">
                      <div className="binduz-er-input-box">
                        <input
                          type="text"
                          placeholder="Email"
                          name="email"
                          id="email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          style={{
                            borderColor:
                              touched?.email && errors?.email && "red",
                          }}
                        />
                        <i className="fal fa-user"></i>
                      </div>
                      {touched?.email && errors?.email && (
                        <span className="text-danger">{errors.email}</span>
                      )}
                    </div>

                    <div
                      className="row col-lg-8 "
                      style={{
                        justifyContent: "space-between",
                        padding: "0px",
                      }}
                    >
                      <div className=" col-lg-6">
                        <div className="binduz-er-input-box ">
                          <input
                            type="number"
                            placeholder="Security Code"
                            name="code"
                            id="code"
                            max={4}
                            value={values.code}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              if (
                                e?.target?.value <= 9999 &&
                                e?.target?.value >= 0
                              ) {
                                handleChange(e);
                              }
                            }}
                            // style={{ fontSize: "27px", paddingLeft: "15px" }}
                          />
                          {/* <i className="fal fa-eye"></i> */}
                        </div>
                      </div>
                      <div className=" col-lg-4">
                        <div className="binduz-er-input-box ">
                          <input
                            type="text"
                            placeholder="Code"
                            style={{
                              fontSize: "27px",
                              paddingLeft: "7px",
                              borderRadius: "10px",
                              textAlign: "center",
                              color: "#e74d58",
                            }}
                            disabled
                            value={securityCode}
                          />
                          {/* <i className="fal fa-eye"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isDisable}
                    className="binduz-er-main-btn contact-us-box-button mt-5"
                  >
                    {isDisable ? "Loading..." : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainSection;
