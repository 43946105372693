import React from "react";
import GoogleMapReact from "google-map-react";

// Custom marker component
const Marker = ({ text }) => (
  <div style={{ color: "red", fontSize: "16px" }}>
    <i className="fas fa-map-marker-alt"></i> {text}
  </div>
);

const MapModule = ({ lat, lng, zoom = 8 }) => {
  const apiKey = process.env.REACT_APP_MAP_KEY;
  return (
    // <div
    //   style={{
    //     height: "500px",
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}

    // >
    //   {/* <GoogleMapReact
    //     bootstrapURLKeys={{ key: apiKey }}
    //     defaultCenter={{ lat: `-34.397`, lng: `150.644` }}
    //     defaultZoom={10}
    //     yesIWantToUseGoogleMapApiInternals
    //   >
    //     <Marker lat={lat} lng={lng} text="Here" />
    //   </GoogleMapReact> */}
    //   <iframe
    //     width="600"
    //     height="450"
    //     style={{ border: "none",borderRadius:'10px' }}
    //     loading="lazy"
    //     referrerPolicy="no-referrer-when-downgrade"
    //     src={`https://maps.google.com/maps?q=${lat},${lng}&z=${zoom}&output=embed`}
    //     title="google map"
    //   ></iframe>
    // </div>
    <div
      style={{
        width: "100%", // Make the map responsive
        maxWidth: "800px", // Set a max width if needed
        margin: "20px auto", // Center the map
        overflow: "hidden", // Ensure any overflow is hidden for rounded corners
        borderRadius: "10px", // Apply border radius to the container
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.4)", // Optional: add a subtle shadow
      }}
    >
      <iframe
        width="100%" // Make iframe responsive
        height="450"
        style={{ border: "none", borderRadius: "10px" }} // Border radius for the iframe
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        src={`https://maps.google.com/maps?q=${lat},${lng}&z=${zoom}&output=embed`}
        title="Google Map"
      ></iframe>
    </div>
  );
};

export default MapModule;
