/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { getPremiumAmount } from "../../services/HomeServices";
import ReactDatePicker from "react-datepicker";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { addMegaphone } from "../../services/AuthServices";
import { dateSendToBackend, formatDateToMMDDYYYY } from "../../lib/constant";
import PremiumModal from "../../containers/PremiumModal";

const ClassiFieldForm = () => {
  const [data, setdata] = useState({});
  const [isTrue, setisTrue] = useState(false);
  const [premiumAmount, setpremiumAmount] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isFirstTime, setisFirstTime] = useState(true);
  const [isDisable, setisDisable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setselectedImage] = useState(null);
  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    getAmount();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  }, []);

  const getAmount = async () => {
    await getPremiumAmount()
      .then((response) => {
        const event = response?.data?.data?.find(
          (a) => a?.type === "Megaphone"
        );
        if (event) {
          setpremiumAmount(Number(event?.amount));
        }
      })
      .catch((error) => {});
  };

  // const getSubCategory = async (id) => {
  //   await getClassifieldSubCatData(id)
  //     .then((response) => {
  //       setsubCatList(response?.data?.data);
  //     })
  //     .catch((error) => {
  //       toast("Something went wrong", { type: "error" });
  //     });
  // };

  const AdminSchema = object().shape({
    title: string().required("Please enter title.").trim("Please enter title."),

    content: string()
      .required("Please enter Content.")
      .trim("Please enter Content."),

    startDate: string()
      .required("Please enter Start Date.")
      .trim("Please enter Start Date."),
  });

  const submitClicked = async (data) => {
    if (!values?.isFree || !isFirstTime) {
      setisDisable(true);
      await addMegaphone(data)
        .then((response) => {
          setisDisable(false);

          if (premiumAmount === 0) {
            toast("Megaphone added Successfully", { type: "success" });
            navigate("/megaphone");
          } else {
            response?.data?.data?.url
              ? window.location.replace(response?.data?.data?.url)
              : navigate("/megaphone");
          }
        })
        .catch((error) => {
          setisDisable(false);

          if (error?.response?.status == 422)
            toast(error?.response?.data?.message, { type: "error" });
          else if (error?.response?.status == 500)
            toast(error.response.data.message, { type: "error" });
          else
            toast(error?.response?.data?.message || "Something went wrong", {
              type: "error",
            });
        });
    } else {
      setModalIsOpen(true);
      setisFirstTime(false);
    }
  };

  const {
    handleSubmit,
    handleBlur,
    setFieldValue,
    handleChange,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: AdminSchema,

    initialValues: {
      title: "",
      startDate: "",
      content: "",
      amount: 0,
      image: null,
    },
    onSubmit: async () => {
      let formdata = new FormData();

      formdata.append("title", values.title);
      formdata.append("start_date", dateSendToBackend(values.startDate));
      formdata.append("content", values.content);
      formdata.append("amount", premiumAmount || 0);
      formdata.append("image", values.image);
      submitClicked(formdata);
    },

    onReset: () => {},
  });

  const handleImageChange = (event, name) => {
    const file = event?.target?.files[0];
    setFieldValue(name, file);
    setselectedImage(URL.createObjectURL(file));
  };

  const removeImage = (name) => {
    setFieldValue(name, null);
    setselectedImage(null);
    fileInputRef.current.value = "";
  };

  return (
    <div
      className="binduz-er-contact-us-box"
      style={{
        marginTop: "3px",
        background: "none",
      }}
    >
      <form onSubmit={((e) => e?.preventDefault(), handleSubmit)}>
        <div
          className="row"
          style={{
            // paddingTop: "15px",
            paddingBottom: "15px",
            // gap: "20px",
          }}
        >
          <div>
            <h7 style={{ fontWeight: "700px" }}> Megaphone Type: </h7>

            <input className="m-2" type="radio" id="premium" checked={true} />
            <span>Premium (${premiumAmount} for 30 days) </span>
          </div>
          <div className=" col-lg-6 ">
            <div className="binduz-er-input-box">
              <input
                type="text"
                placeholder="Title *"
                name="title"
                id="title"
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{
                  borderColor: touched?.title && errors?.title && "red",
                }}
              />
            </div>
            {touched?.title && errors?.title && (
              <span className="text-danger">{errors.title}</span>
            )}
          </div>

          <div className="col-lg-6 ">
            <div className="binduz-er-input-box ">
              <ReactDatePicker
                autoComplete="off"
                placeholderText="Select Start Date *"
                value={values?.startDate}
                minDate={new Date()}
                onChange={(e) => {
                  setFieldValue("startDate", formatDateToMMDDYYYY(new Date(e)));
                }}
                onBlur={handleBlur}
                name="date"
              />
            </div>
            {touched?.startDate && errors?.startDate && (
              <span className="text-danger">{errors.startDate}</span>
            )}
          </div>

          <div
            className=" col-lg-12"
            style={{
              textAlign: "center",
              //  paddingTop: "100px"
            }}
          >
            <div className="binduz-er-input-box">
              <textarea
                style={{
                  height: "180px",
                  resize: "auto",
                  borderColor: touched?.content && errors?.content && "red",
                }}
                maxLength={300}
                name="content"
                id="content"
                onChange={handleChange}
                value={values?.content}
                onBlur={handleBlur}
                cols="3"
                rows="1"
                placeholder="Your Content *"
              ></textarea>
              {touched?.content && errors?.content && (
                <div
                  style={{
                    textAlign: "start",
                    // marginLeft: "5px",
                    // fontSize: "14px",
                  }}
                  className="text-danger"
                >
                  {errors.content}
                </div>
              )}
              <div
                style={{
                  textAlign: "start",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
              >
                Chars Remaining: {300 - values?.content?.length}
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="binduz-er-input-box">
              <label htmlFor="image" className="custom-file-label">
                Choose Image
              </label>
              <input
                type="file"
                style={{ paddingLeft: "17px" }}
                placeholder="Image *"
                accept="image/*"
                name="image"
                id="image"
                ref={fileInputRef}
                multiple
                // value={imagePreview}
                onChange={(e) => {
                  handleImageChange(e, "image");
                }}
                onBlur={handleBlur}
                className="form-control custom-file-input"
              />
            </div>
            {selectedImage && (
              <>
                <a href={selectedImage} target="_blank" className="m-1">
                  <i className="fal fa-eye" style={{ cursor: "pointer" }} />
                </a>
                <a className="m-1" onClick={() => removeImage("image")}>
                  <i className="fal fa-trash" style={{ cursor: "pointer" }} />
                </a>
              </>
            )}
            <span>( Max Width/Height: 1150px * 500px )</span>
          </div>
        </div>
        <button
          type="submit"
          disabled={isDisable}
          onClick={handleSubmit}
          className="binduz-er-main-btn contact-us-box-button mt-5"
        >
          {isDisable ? "Loading..." : "Proceed to payment"}
        </button>
      </form>

      {/* <PremiumModal
        yesClicked={() => {
          setFieldValue("isFree", false);
          setModalIsOpen(false);
        }}
        noClicked={() => {
          setFieldValue("isFree", true);
          setModalIsOpen(false);
        }}
        title={"CLASSIFIED"}
        amount={premiumAmount}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        topLinesArray={[
          "You can post your classifieds for free on our platform, and they will be valid for 15 days.Enjoy the convenience and reach of our service without any cost!",
          `For just $${premiumAmount}, you can upgrade your classified and unlock these exclusive benefits:`,
        ]}
        descArray={[
          " Your classified will be active for 30 days, giving you double the exposure.",
          " Your classified will be moved to a spotlight position, ensuring better visibility and traction.",
          " Upload up to 4 images, making your classified more attractive and informative.",
          " Your classified will appear at the top of search results, catching the eye of potential customers first.",
        ]}
        titleArray={[
          "Extended Visibility:-",
          "⁠Spotlight Position:-",
          "Enhanced Media Uploads:-",
          "Priority Listing:-",
        ]}
        lastLine={`Upgrading to Premium ensures your classified stands out, attracts more attention, and reaches the right audience effectively. Post for free and upgrade to Premium to maximize your results!`}
      /> */}
    </div>
  );
};

export default ClassiFieldForm;
