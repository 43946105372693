/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import MovieReviewCart from "../../containers/MovieReviewCart";
import JobPosts from "../../containers/JobPosts";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getDoctorData,
  getJobsData,
  getPremiumDoctorData,
} from "../../services/HomeServices";
import DoctorPost from "../../containers/DoctorPost";
import { ListGroup } from "react-bootstrap";
import { last } from "lodash";
// import SliderCom from "../Helpers/SliderCom";

function FeatureSection({ featurePosts }) {
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [doctorData, setdoctorData] = useState([]);
  const [docLoading, setdocLoading] = useState(false);
  const [page, setpage] = useState(1);
  const [lastPage, setlastPage] = useState(0);
  const [toggle, settoggle] = useState(0);
  const navigate = useNavigate();
  let widthOfPage = window.innerWidth > 700;

  useEffect(() => {
    getDocData(page);
    getData();
  }, []);

  useEffect(() => {
    if (toggle > 0 && page !== lastPage) {
      getDocData(page + 1);
      setpage(page + 1);
    }else if(lastPage!==1){
       getDocData(1);
      setpage(1);
    }
  }, [toggle]);


  const getData = async () => {
    setisLoading(true);
    await getJobsData("", "", 1, "", 12)
      .then((response) => {
        setisLoading(false);
        setdata(response?.data?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);

        toast("Something went wrong", { type: "error" });
      });
  };

  const getDocData = async (pageNumber) => {
    setdocLoading(true);

    await getPremiumDoctorData(pageNumber || page)
      .then((response) => {
        let resData = response?.data?.data?.data;
        setlastPage(response?.data?.data?.last_page);
        // setlastPage(2);
        setdoctorData(resData);
        setdocLoading(false);
      })
      .catch((error) => {
        setdocLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };
  return (
    <>
      <section
        className="binduz-er-featured-area"
        // style={{ backgroundColor: "#0d6efd" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="binduz-er-top-news-title d-lg-flex"
                style={{
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  justifyContent: "space-between",
                }}
              >
                <h3 className="binduz-er-title">
                  <i
                    className="far fa-briefcase"
                    style={{ color: "red", marginRight: "5px" }}
                  ></i>
                  JOBS
                </h3>
                <div className="d-lg-flex" style={{ gap: "7px" }}>
                  <h2
                    className="classifield-text"
                    style={{
                      height: "40px",
                      // marginLeft: "25px",
                      display: "flex",
                      // margin-right: 43px;
                      marginTop: "20px",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={() => navigate("/jobs-post")}
                  >{`POST +`}</h2>
                  <h2
                    className="classifield-text"
                    style={{
                      height: "40px",
                      // marginLeft: "25px",
                      display: "flex",
                      // margin-right: 43px;
                      marginTop: "20px",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={() => navigate("/jobs")}
                  >{`VIEW ALL >>`}</h2>
                </div>
              </div>

              <JobPosts data={data} isLoading={isLoading} />
            </div>
            <div className="col-lg-12">
              <div
                className="binduz-er-top-news-title"
                style={{
                  display: widthOfPage ? "flex" : "",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  paddingTop: "65px",
                  justifyContent: "space-between",
                }}
              >
                <h3 className="binduz-er-title">
                  <i
                    className="far fa-medkit"
                    style={{ color: "red", marginRight: "5px" }}
                  ></i>
                  DOCTORS
                </h3>
                <div className="d-lg-flex" style={{ gap: "7px" }}>
                  <h2
                    className="classifield-text"
                    style={{
                      height: "40px",
                      // marginLeft: "25px",
                      display: "flex",
                      // margin-right: 43px;
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={() => navigate("/doctor-post")}
                  >{`POST +`}</h2>
                  <h2
                    className="classifield-text"
                    style={{
                      height: "40px",
                      // marginLeft: "25px",
                      display: "flex",
                      // margin-right: 43px;
                      // marginTop: "20px",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={() => navigate("/doctor")}
                  >{`VIEW ALL >>`}</h2>
                </div>
              </div>

              <DoctorPost
                data={[...doctorData]}
                isLoading={docLoading}
                setDataKey={settoggle}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeatureSection;
