import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navigation from "../../../Helpers/Navigation";
import { isLogin } from "../../../../lib/constant";
import DropDown from "../../../Helpers/DropDown";
import PopupModal from "../../../../containers/PopupModal";
import { toast } from "react-toastify";
import { logoutApi } from "../../../../services/AuthServices";
import { useGlobalStore } from "../../../../lib/store";

const MainHeader = ({ drawerAction, searchAction }) => {
  const [searchBarMobileActive, setSearchBarMobileActive] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const { search, setSearch } = useGlobalStore();

  const logOutClicked = async (e) => {
    e.preventDefault();
    localStorage.removeItem("is_authenticated");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");

    isLogin();
    navigate("/");
    setModalIsOpen(false);
    toast("Logout Successfully", { type: "success" });

    // await logoutApi()
    //   .then(() => {
    //     localStorage.removeItem("is_authenticated");
    //     localStorage.removeItem("token");
    //     localStorage.removeItem("userId");
    //  toast("Logout Successfully", { type: "success" });
    //     isLogin();
    //  setModalIsOpen(false);
    //     navigate("/");
    //   })
    //   .catch((error) => {
    //     if (error?.response?.status == 422)
    //       toast(error.response.data.error, { type: "error" });
    //     else if (error?.response?.status == 500)
    //       toast(error.response.data.message, { type: "error" });
    //     else toast("Something went wrong", { type: "error" });
    //   });
  };

  return (
    <>
      <header className="binduz-er-header-area">
        <div className="binduz-er-header-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="navigation">
                  <nav className="navbar navbar-expand-lg">
                    <div className="navbar-brand logo">
                      <Link to="/">
                        <img
                          style={{ maxWidth: "178px" }}
                          src={
                            require(`../../../../assets/images/az-logo.png`)
                              .default
                          }
                          alt=""
                        />
                      </Link>
                    </div>
                    <div
                      className="collapse navbar-collapse sub-menu-bar"
                      id="navbarSupportedContent"
                    >
                      <Navigation className="m-auto" />
                    </div>
                    <div className="binduz-er-navbar-btn d-flex">
                      <div className="binduz-er-widget d-flex">
                        {/* <a
                          // onClick={(e) => searchAction(e)}
                          className="binduz-er-news-search-open"
                          // href="#"
                          onClick={() => setisExpanded(!isExpanded)}
                        >
                          <i
                            className="far fa-search"
                            style={{ color: "white" }}
                          ></i>
                         
                        </a> */}
                        <div
                          className="binduz-er-navbar-btn d-flex"
                          style={{ alignItems: "center" }}
                        >
                          <div
                            className={`search-bar-mobile ${
                              searchBarMobileActive
                                ? "search-bar-mobile-active"
                                : ""
                            }`}
                          >
                            <form
                              className="search-form-mobile"
                              onSubmit={(e) => {
                                e?.preventDefault();
                                navigate(`/search`);
                              }}
                            >
                              <i
                                style={{
                                  color: searchBarMobileActive
                                    ? "black"
                                    : "white",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e?.preventDefault();
                                  setSearchBarMobileActive(true);
                                  if (searchBarMobileActive) {
                                    navigate(`/search`);
                                  }
                                }}
                                className={` far fa-search nav-icon search-icon-mobile  ${
                                  searchBarMobileActive
                                    ? "search-icon-mobile-active"
                                    : ""
                                }`}
                              ></i>

                              <i
                                // style={{ color: "white" }}
                                className={`far fa-window-close search-close-button-mobile ${
                                  searchBarMobileActive
                                    ? "search-close-button-mobile-active"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSearchBarMobileActive(false);
                                  setSearch("");
                                }}
                              ></i>

                              {searchBarMobileActive && (
                                <input
                                  type="text"
                                  className={`search-input-mobile ${
                                    searchBarMobileActive
                                      ? "search-input-mobile-active"
                                      : ""
                                  }`}
                                  value={search}
                                  onChange={(e) => setSearch(e?.target?.value)}
                                  // onBlur={() =>
                                  //   !search && setSearchBarMobileActive(false)
                                  // }
                                />
                              )}
                              <button
                                type="submit"
                                className="search-submit-button-mobile"
                              ></button>
                            </form>
                          </div>
                          <ul
                            className={`navbar-nav `}
                            style={{ paddingRight: "30px" }}
                          >
                            <li key={Math.random()} className="nav-item active">
                              <Link className="nav-link d-flex">
                                <i
                                  className="far fa-user"
                                  style={{ color: "white" }}
                                ></i>
                                <i className="fa fa-angle-down"></i>
                              </Link>
                              <ul
                                className="sub-menu"
                                style={{
                                  backgroundColor: "black",
                                  width: "100%",
                                  minWidth: "140px",
                                  marginLeft: "-50px",
                                }}
                              >
                                {isLogin() ? (
                                  <>
                                    <li key={Math.random()}>
                                      <Link to={"/account-detail"}>
                                        {"Account"}
                                      </Link>
                                    </li>
                                    {/* <li key={Math.random()}>
                                    <Link to={`/subscription`}>
                                      {"Subscription"}
                                    </Link>
                                  </li> */}
                                    <li
                                      key={Math.random()}
                                      onClick={() => setModalIsOpen(true)}
                                    >
                                      <Link>{"Log Out"}</Link>
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    <li key={Math.random()}>
                                      <Link to={"/login"}>{"Login"}</Link>
                                    </li>
                                    <li key={Math.random()}>
                                      <Link to={"/register"}>{"Register"}</Link>
                                    </li>
                                  </>
                                )}
                              </ul>
                            </li>
                          </ul>
                          
                        </div>
                        <PopupModal
                          title="Are you sure you want to Logout ?"
                          modalIsOpen={modalIsOpen}
                          setModalIsOpen={setModalIsOpen}
                          yesClicked={logOutClicked}
                        />
                      </div>

                      <span
                        onClick={drawerAction}
                        className="binduz-er-toggle-btn binduz-er-news-canvas_open d-block d-lg-none"
                      >
                        <i className="fal fa-bars"></i>
                      </span>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default MainHeader;
