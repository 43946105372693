import React from "react";
import img from "../../assets/images/azlogomessage.jpeg";

const ClassiFieldCart = ({ isPremium, name, desc, index, onClick, image }) => (
  <div
    className="binduz-er-sidebar-add-box"
    style={{
      backgroundImage: `url(${image || img})`,
      display: !name ? "flex" : "",
      justifyContent: "center",
      alignItems: "center",
    }}
    onClick={onClick}
  >
    {name && (
      <div className="classifield-text" style={{ color: "black" }}>
        <a> {name}</a>
      </div>
    )}
    <p
      style={{ fontSize: "16px", color: isPremium ? "white" : "" }}
      className="line-clump-5"
    >
      {desc}
    </p>
  </div>
);

export default ClassiFieldCart;
