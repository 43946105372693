import React, { useEffect, useState } from "react";
import SliderCom from "../Helpers/SliderCom";
import { useNavigate } from "react-router-dom";
import { getCatDetail, getPremiumEvent } from "../../services/HomeServices";
import { toast } from "react-toastify";
import img from "../../assets/images/azlogomessage.jpeg";
import Loader from "../../containers/Loader";

function PremiumEvent() {
  const [eventDetails, seteventDetails] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getEventDetails();
  }, []);

  const getEventDetails = async () => {
    setisLoading(true);
    await getPremiumEvent()
      .then((response) => {
        setisLoading(false);
        seteventDetails(response?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    speed: 1500,
    slidesToShow: eventDetails?.length < 5 ? eventDetails?.length : 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "50px",
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          arrows: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <section
      className="
    binduz-er-news-slider-2-area
     pt-30 pb-30 
    "
    >
      <div className=" container-fluid p-0">
        <div className="row">
          <div className=" col-lg-12">
            <div
              style={{ backgroundColor: "none" }}
              //  className="binduz-er-news-slider-2-item"
            >
              {!isLoading ? (
                <SliderCom settings={settings}>
                  {eventDetails?.map((x) => (
                    <div
                      // className="binduz-er-news-viewed-most"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          `/ticket-details/${x?.location_id}/${x?.event_id}`
                        )
                      }
                    >
                      <div
                        className="premium-event-card  "
                        style={{
                          height: "320px",
                          // width: "333px",
                          display: "flex",
                        }}
                      >
                        <img
                          className="image-shadow "
                          src={x?.event?.flyer || img}
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
                </SliderCom>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    minHeight: "320px",
                  }}
                >
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PremiumEvent;
