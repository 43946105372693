import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import img from "../assets/images/azlogomessage.jpeg";
import { useInView } from "react-intersection-observer";
import "./index.css";
import { plainString } from "../lib/constant";

const DoctorPost = ({ data, isLoading, setDataKey }) => {
  const [hasAnimatedRight, setHasAnimatedRight] = useState(false);
  const navigate = useNavigate();
  const controlsRight = useAnimation();
  const { ref: refRight, inView: inViewRight } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewRight && !hasAnimatedRight) {
      controlsRight.start("visible");
      setHasAnimatedRight(true);
    }
  }, [controlsRight, inViewRight]);

  useEffect(() => {
    const timer = setInterval(() => {
      setDataKey((prevKey) => prevKey + 1); // Increment key to trigger animation
    }, 8000);

    return () => clearInterval(timer);
  }, []);

  const boxVariantsRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  const fadeVariants = {
    hidden: { opacity: 0, y: 10 }, // starts slightly below and transparent
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }, // fades in and moves up
    exit: { opacity: 0, y: -10, transition: { duration: 0.5 } }, // fades out and moves up
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        className="row"
        style={{ paddingLeft: "40px", paddingRight: "40px",minHeight:'460px'}}
        // ref={refRight}
        // initial="hidden"
        // animate={controlsRight}
        // variants={boxVariantsRight}
        key={data} // Ensures the element remounts when data changes
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={fadeVariants}
      >
        {!isLoading ? (
          <>
            {data?.slice(0, 11)?.map((x, index) => (
              <motion.div
                className="custom-box col-lg-2 m-3"
                style={{
                  backgroundImage: `url(${x?.profile_image || img})`,
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/doctor/${x?.id}`, { state: x })}
                whileHover={{ scale: 1.05 }} // Scale up the image on hover
                transition={{ type: "spring", stiffness: 300 }} // Smooth transition with spring effect
              >
                <motion.div className="custom-box-text">
                  <div className="classifield-text" style={{ color: "black" }}>
                    <a> {x?.name}</a>
                  </div>
                  <p
                    style={{
                      fontSize: "16px",
                      textWrap: "balance",
                      marginTop: "55px",
                    }}
                    className="line-clump-3"
                  >
                    {plainString(x?.address)}
                  </p>
                </motion.div>
              </motion.div>
            ))}
          </>
        ) : (
          <Loader />
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default DoctorPost;
