import React, { useEffect, useRef, useState } from "react";
import img from "../../assets/images/azlogomessage.jpeg";
import img1 from "../../assets/images/az1.jpeg";
import img2 from "../../assets/images/az2.jpeg";
import img3 from "../../assets/images/az3.jpeg";
import img4 from "../../assets/images/az4.jpeg";
import img5 from "../../assets/images/adazindia.jpeg";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { getLatestClassifieldData } from "../../services/HomeServices";
import { toast } from "react-toastify";
import _ from "lodash";
import EventModule from "../EventModule";
import LocalBussinessModule from "../LocalBussinessModule";
import DoctorModule from "../DoctorModule";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {  Overlay, Tooltip } from "react-bootstrap";

export const imgArray = [
  img,
  img1,
  img2,
  img3,
  img4,
  img5,
  img4,
  img3,
  img,
  img3,
  img2,
  img5,
  img3,
];

const SideADModule = ({
  isEvent = false,
  isAd = false,
  isClassifield = false,
  isLocaBussiness = false,
  isDoctor = false,
}) => {
  const navigate = useNavigate();
  const [latestClassiLoading, setlatestClassiLoading] = useState(false);
  const [latestClassiData, setlatestClassiData] = useState([]);
  const [hasAnimatedRight, setHasAnimatedRight] = useState(false);
  const [show, setShow] = useState(false);
  const target = useRef(null);

 


  useEffect(() => {
    latestClassifeildData();
  }, []);

  const controlsRight = useAnimation();
  const { ref: refRight, inView: inViewRight } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewRight && !hasAnimatedRight) {
      controlsRight.start("visible");
      setHasAnimatedRight(true); // Set the state to prevent re-triggering
    }
  }, [controlsRight, inViewRight]);

  const boxVariantsRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  const latestClassifeildData = async () => {
    setlatestClassiLoading(true);
    await getLatestClassifieldData()
      .then((response) => {
        setlatestClassiLoading(false);
        setlatestClassiData(response?.data?.data);
      })
      .catch((error) => {
        setlatestClassiLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };

  return (
    <>
      {isClassifield && (
        <>
          <div
            className="binduz-er-top-news-title d-flex"
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <h3 className="binduz-er-title">LATEST CLASSIFIEDS</h3>
            <i
              ref={target}
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
              onClick={() => navigate("/classifield")}
              className="fal fa-arrow-right"
              style={{ marginRight: "18px", color: "red", cursor: "pointer" }}
            ></i>
            <Overlay target={target.current} show={show} placement="top">
              {(props) => (
                <Tooltip id="overlay-example" {...props}>
                  VIEW CLASSIFIEDS
                </Tooltip>
              )}
            </Overlay>
          </div>
          <div
            className="binduz-er-social-list"
            style={{ height: "450px", overflow: "scroll" }}
          >
            <div className="binduz-er-list">
              {!latestClassiLoading ? (
                <>
                  {!_.isEmpty(latestClassiData) ? (
                    <>
                      {latestClassiData?.map((x) => (
                        <motion.div>
                          <Link
                            className="category-search"
                            style={{minHeight:'70px'}}
                            to={`/classifield-detail/${x?.id}`}
                          >
                            <span>
                              <span
                                style={{
                                  cursor: "pointer",
                                }}
                                className="line-clump-2"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: x?.description,
                                  }}
                                />
                              </span>
                            </span>
                          </Link>
                        </motion.div>
                      ))}
                    </>
                  ) : (
                    <Link
                      className="category-search"
                      style={{
                        height: "400px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span>
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <h5 style={{ textAlign: "center" }}>No Data found</h5>
                        </span>
                      </span>
                    </Link>
                    // <h5 style={{ textAlign: "center" }}>No Data found</h5>
                  )}
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </>
      )}

      {isEvent && <EventModule />}
      {isLocaBussiness && <LocalBussinessModule />}
      {isDoctor && <DoctorModule />}
      {isAd && (
        <div
          className="binduz-er-footer-gallery "
          style={{ marginTop: "77px" }}
        >
          <div className="binduz-er-footer-gallery-widget d-flex">
            {[1, 2]?.map((x) => (
              <div className="binduz-er-item">
                <a href="#">
                  <img
                    height={155}
                    width={165}
                    src={imgArray[x + 3]}
                    //   src={require(`../../assets/images/classifield.png`).default}
                    alt=""
                  />
                </a>
              </div>
            ))}
          </div>
          <div className="binduz-er-footer-gallery-widget d-flex">
            {[1, 2]?.map((x) => (
              <div className="binduz-er-item">
                <a href="#">
                  <img
                    height={155}
                    width={165}
                    src={imgArray[x]}
                    //   src={require(`../../assets/images/classifield.png`).default}
                    alt=""
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default SideADModule;
