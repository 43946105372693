import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import VideoPopUp from "./VideoPopUp";
import { formatDateToMMDDYYYY } from "../lib/constant";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const VideoModule = (props) => {
  const {
    children,
    datas = { image: "", category: "", date: "", title: "", link: "" },
    className = "",
    video = true,
  } = props;
  const [show, setValue] = useState(false);
  const [hasAnimatedLeft, setHasAnimatedLeft] = useState(false);

  const controlsLeft = useAnimation();

  const { ref: refLeft, inView: inViewLeft } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inViewLeft && !hasAnimatedLeft) {
      controlsLeft.start("visible");
      setHasAnimatedLeft(true); // Set the state to prevent re-triggering
    }
  }, [controlsLeft, inViewLeft]);

  const boxVariantsLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  };

  const videoId = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|.+&v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };
  return (
    <>
      {show && video && (
        <VideoPopUp setValue={setValue} videoSrc={datas?.link} />
      )}
      <motion.div
        className={`binduz-er-trending-news-list-box ${className || ""}`}
        ref={refLeft}
        initial="hidden"
        animate={controlsLeft}
        variants={boxVariantsLeft}
      >
        <div
          className="binduz-er-thumb"
          style={{ marginBottom: !video ? "0" : "", position: "relative" }}
        >
          <img
            // src={datas?.image}
            src={`https://img.youtube.com/vi/${videoId(
              datas?.link
            )}/sddefault.jpg`}
            alt=""
          />
          <div
            className="binduz-er-play"
            style={{
              display: "flex",
              position: "absolute",
              right: " 20px",
              cursor: "pointer",
              top: "20px",
            }}
          >
            <a
              // onClick={(e) => setValue.toggle(e)}
              onClick={() => setValue(true)}
              className="binduz-er-video-popup"
              style={{
                backgroundColor: "red",

                height: "50px",
                width: "50px",
                display: "inline-block",
                lineHeight: "50px",
                color: "#f63a3a",
                borderRadius: "5px",
                background: "#fff",
                textAlign: "center",
              }}
            >
              <i className="fas fa-play"></i>
            </a>
          </div>
        </div>
        <div className="binduz-er-content">
          <div className="binduz-er-meta-item">
            <div className="binduz-er-meta-categories">
              <a>{datas.category}</a>
            </div>
            <div className="binduz-er-meta-date">
              <span>
                <i
                  className="fal fa-calendar-alt"
                  style={{ marginRight: "5px" }}
                ></i>
                {formatDateToMMDDYYYY(new Date(datas?.date))}
              </span>
            </div>
          </div>
          <div className="binduz-er-trending-news-list-title">
            <h4 className="binduz-er-title line-clump-2">
              <Link to="/blog-details-two">{datas.title}</Link>
            </h4>
            {children && children}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default VideoModule;
