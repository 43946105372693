import React, { useEffect, useState } from "react";
import PremiumEvent from "../HomeOne/PremiumEvent";
import { motion, useAnimation } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../lib/constant";
import { getCatDetail } from "../../services/HomeServices";
import { toast } from "react-toastify";
import img from "../../assets/images/azlogomessage.jpeg";
import SideADModule from "../../containers/PostCards/SideADModule";

const NormalView = () => {
  const [eventDetails, seteventDetails] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getEventDetails();
  }, []);

  const getEventDetails = async () => {
    setisLoading(true);
    await getCatDetail()
      .then((response) => {
        setisLoading(false);
        seteventDetails(response?.data?.data);
      })
      .catch((error) => {
        setisLoading(true);
        toast("Something went wrong", { type: "error" });
      });
  };


  return (
    <div>
      <PremiumEvent />
      <div className="col-lg-12 row mt-4">
        <div
          className="col-lg-9"
          style={{ maxHeight: "950px", overflowY: "scroll", padding: "25px" }}
        >
          {eventDetails?.map((x) => (
            <motion.div
              whileHover={{ scale: 1.05 }} // Scale up the image on hover
              transition={{ type: "spring", stiffness: 300 }} // Smooth transition with spring effect
              className="card event-card mt-4"
              style={{ borderRadius: "20px" }}
              onClick={() =>
                navigate(`/ticket-details/${x?.location_id}/${x?.event_id}`)
              }
            >
              <div className="card-body d-flex align-items-center p-0">
                {/* <div
                  className="col-lg-2"
                  style={{ textAlign: "center", marginRight: "4px" }}
                > */}
                <img
                  // src={item?.image || img}
                  src={
                    x?.event?.flyer ||
                    x?.event?.sponsor_logo ||
                    x?.event?.image ||
                    img
                  }
                  style={{
                    top: "0%",
                    display: "inline-block",
                    height: "108px",
                    left: "0%",
                    marginRight: "4px",
                  }}
                  className="event-card-img col-lg-2"
                  alt="AZIndia Logo"
                />
                {/* </div> */}
                <div
                  className="event-card-content col-lg-8"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="binduz-er-title line-clump-2">
                    <Link
                      to={"/ticket-details/${x?.location_id}/${x?.event_id}"}
                      //   state={item}
                    >
                      {x?.event?.name}
                    </Link>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center event-card-footer"
                    style={{ paddingBottom: "10px", paddingRight: "30px" }}
                  >
                    <div className="binduz-er-meta-author">
                      {/* <div
                        dangerouslySetInnerHTML={{
                          __html: x?.location?.address,
                        }}
                      /> */}
                      <p>{x?.location?.name}</p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-2"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f63a3a",
                    padding: "28px",
                    color: "black",
                  }}
                >
                  <div className="binduz-er-meta-date">
                    <span>
                      <i
                        className="fal fa-calendar-alt"
                        style={{ marginRight: "7px" }}
                      ></i>
                      {formatDate(x?.date)}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
        <div className="col-lg-3">
          <SideADModule isClassifield={true} isDoctor={true} />
        </div>
      </div>
    </div>
  );
};

export default NormalView;
